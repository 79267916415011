import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import "./OpinionsSection.scss";
import { useLang } from "../../../../features/lang-switch/useLang";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import OpinionTile from "./components/OpinionTile/OpinionTile";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import MarcinImg from "./marcin_wolodzko.jpeg";
import WojciechImg from "./wojciech_stawiarski.jpeg";

interface OpinionsSectionProps { }

const OpinionsSection: React.FC<OpinionsSectionProps> = (props) => {
  const { T } = useLang();
  return (
    <section className="OpinionsSection py-3 pt-4 py-xl-3">
      <Container className="pb-md-4 pb-5">
        <Row className="py-xl-5 pt-4 pb-3">
          <Col size={[6, 8, 10, 12]} offset={[3, 2, 1, 0]} className="section-intro">
            <h2 className="py-xl-3">{T("Opinie o nas", "Few words about us")}</h2>
          </Col>
        </Row>
        <Row>
          <Swiper
            modules={[Navigation, Pagination, A11y]}
            navigation
            pagination={{ clickable: true }}
            spaceBetween={24}
            slidesPerView={1}
            onSlideChange={() => console.log("onSlideChange")}
            onSwiper={(swiper) => console.log("onSwiper")}
            // loop

            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 24,
              },
              900: {
                slidesPerView: 2,
                spaceBetween: 32,
              },
              1200: {
                slidesPerView: 2,
                spaceBetween: 48,
              },
            }}
          >
            <SwiperSlide>
              <OpinionTile
                icon={MarcinImg}
                quote={
                  T(<p>
                    <i>Sukcesy w Crowdfundingu sprawiły, że mamy na pokładzie ponad 1400 akcjonariuszy, a jednym z naszych istotniejszych zadań jest kontakt i obsługa zarówno naszych obecnych jak i potencjalnych przyszłych inwestorów. Podejmując współpracę z Irmatiq zyskaliśmy narzędzie, które dało nam to co najważniejsze, czyli czas. Dzięki temu, możemy skupić się na tym co kochamy - rozwijaniu Spółki i tworzeniu innowacyjnych konopnych produktów.</i>
                  </p>,
                    <p>
                      <i>With our success in Crowdfunding, we have more than 1,400 shareholders on board and one of our most important tasks is to contact and serve both our current and potential future investors. By partnering with Irmatiq, we have gained a tool that has given us what is most important - time. As a result, we can focus on what we love - growing the Company and creating innovative cannabis products.</i>
                    </p>
                  )
                }
              >
                <h3>Marcin Wołodźko</h3>
                <h4>Finance & Accounting Specialist @ Kombinat Konopny</h4>
              </OpinionTile>
            </SwiperSlide>
            <SwiperSlide>
              <OpinionTile
                icon={WojciechImg}
                quote={
                  T(<p>
                    <i>REAKTO jest jedną z nielicznych spółek deeptech B2B (jeżeli nie jedyną na polskiej arenie startupowej), która z powodzeniem zreazlizowała kampanię equity-crowdfundingową w 2021 roku. Oprogramowanie Irmatiq pozwoli nam na wygodne zarządzanie naszym akcjonariatem, liczącym ponad 140 inwestorów. Rozwiązanie zostało przez nas przetestowane i wraz z najbliższą aktualizacją rejestru akcjonariatu zamierzamy rozpocząć korzystanie z apliakcji.</i>
                  </p>,
                    <p>
                      <i>REAKTO is one of the few B2B deeptech companies (if not the only one in the Polish startup arena) that has successfully reazlized an equity-crowdfunding campaign in 2021. Irmatiq software will allow us to conveniently manage our shareholder base of over 140 investors. We have tested the solution and intend to start using the apliaction with the next update of the shareholder register.</i>
                    </p>
                  )
                }
              >
                <h3>Wojciech Stawiarski</h3>
                <h4>CEO @ REAKTO</h4>
              </OpinionTile>
            </SwiperSlide>
          </Swiper>
        </Row>
      </Container>
    </section>
  );
};

export default OpinionsSection;
