import React, { useEffect, useState } from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import "./BlogListSection.scss";
import { useLang } from "../../../../features/lang-switch/useLang";
import ArticleTile from "../../../../components/ArticleTile/ArticleTile";
import { RoutePaths } from "../../../../route-paths";
import { generatePath, useParams } from "react-router-dom";
import { fetchArticles } from "../../fetchData";

interface BlogListSectionProps {}

const BlogListSection: React.FC<BlogListSectionProps> = (props) => {
  const { T, lang } = useLang();
  const [articles, setArticles] = useState<any>([]);
  const { tag } = useParams();
  const fetchData = () => {
    fetchArticles(lang, tag)
      .then((res) => {
        setArticles(res);
      })
      .catch((err) => console.log(err));

    // let articlesUrl = `/blog-data/articles.json`;
    // fetch(articlesUrl)
    //   .then((res) => res.json())
    //   .then((out) => {
    //     setArticles(out);
    //   })
    //   .catch((err) => console.log(err));
  };

  // useEffect(() => {
  //   fetchArticles();
  // }, [])

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <section className="BlogListSection mb-5 py-3 py-xl-5">
      <Container>
        <Row className="pt-3" align="center">
          <Col size={12}>
            <h1 className="h1 primary">
              {T("Najnowsze artykuły", "Latest articles")}
              {tag ? ` / ${tag}` : ""}
            </h1>
          </Col>
        </Row>
        <Row className="py-3 pb-xl-5 pt-xl-2" gap={32}>
          {articles.map((item: any, index: number) => {
            var article = item.attributes;
            return (
              <Col size={[4, 4, 6, 12]} className="py-4 py-xl-5" key={index}>
                <ArticleTile
                  img={article.PreviewImage?.data?.attributes?.url}
                  to={generatePath(RoutePaths.ARTICLE.url[lang], {
                    id: item.id,
                    name: article.Url,
                  })}
                  title={article.Title}
                  description={article.Description}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default BlogListSection;
