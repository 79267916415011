import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import "./LargeMidTextSectionWithBg.scss";
import CirclesImg from "./circles.svg";
import { useLang } from "../../../../features/lang-switch/useLang";

interface LargeMidTextSectionWithBgProps {}

const LargeMidTextSectionWithBg: React.FC<LargeMidTextSectionWithBgProps> = (props) => {
  const { T } = useLang();

  return (
    <section className="LargeMidTextSectionWithBg py-4 my-4">
      <Container className="pt-3 mt-4">
        <div className="section-bg" style={{ backgroundImage: `url(${CirclesImg})` }}>
          <Row className="py-0 my-0 py-xl-5 my-xl-4">
            <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
              <div className="">
                <h1 className="h1">{T(<>Poznaj kompletne narzędzie do zarządzania inwestorami</>, <>All-in-one tool for investor management</>)}</h1>
                <p className="pt-4">{T(<>Usprawnij komunikację z inwestorami dzięki kompleksowemu narzędziu od Irmatiq</>, <>With Irmatiq, you will optimize communications with your company's investors.</>)}</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default LargeMidTextSectionWithBg;
