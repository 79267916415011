import React from "react";
import "./Tile.scss";

interface TileProps {
  children: React.ReactNode;
  icon?: string;
  footer?: React.ReactNode;
  className?: string;
}

const Tile: React.FC<TileProps> = (props) => {
  const { children, icon, footer, className = "" } = props;

  return (
    <div className={`Tile ${footer ? "" : "Tile--no-footer"} ${className}`}>
      {icon ? <div className="icon" style={{ backgroundImage: `url(${icon})` }} /> : null}
      {children}
      {footer ? <div className="tile-footer">{footer}</div> : null}
    </div>
  );
};

export default Tile;
