import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { RoutePaths } from "../../route-paths";
import ArticleHeroSection from "./sections/ArticleHeroSection/ArticleHeroSection";
import "./article-page.scss";
import RelatedListSection from "./sections/RelatedListSection/RelatedListSection";
import HelmetSection from "./sections/HelmetSection/HelmetSection";
import { fetchArticleBase, fetchSingleArticle } from "./fetchData";
import ContentBlocks from "./sections/ContentBlocks/ContentBlocks";
import useEffectAfterMounted from "../../features/useEffectAfterMounted";

interface Props {}

const ArticlePage: React.FC<Props> = (props: Props) => {
  const { id } = useParams();
  const location = useLocation();

  // const [article, setArticle] = useState<any>();
  const [urlLang, setUrlLang] = useState<"pl" | "en">(location.pathname.indexOf("/en/") !== -1 ? "en" : ((location.pathname.indexOf("/pl/") !== -1 ? "pl" : undefined) as "pl" | "en"));

  const [articleBases, setArticlesBases] = useState<any>();

  // const location = useLocation();

  const fetchData = async () => {
    // setArticle(null);
    setArticlesBases(null);
    var articlesBases = await fetchArticleBase(id);
    if (articlesBases) {
      var plArticle = articlesBases.find((x) => x.locale === "pl");
      var enArticle = articlesBases.find((x) => x.locale === "en");
      setArticlesBases({
        pl: await fetchSingleArticle(plArticle?.id),
        en: await fetchSingleArticle(enArticle?.id),
      });
    }
  };

  // useEffectAfterMounted(() => {
  // if (articleBases === undefined) navigate(generatePath(RoutePaths.BLOG.url[lang]));
  // var articlePl = articleBases.pl;
  // var articleEn = articleBases.en;

  // if (lang === "pl") {
  //   navigate(generatePath(RoutePaths.ARTICLE.url.pl, { id: articlePl.id, name: articlePl.Url }));
  // } else if (lang === "en") {
  //   navigate(generatePath(RoutePaths.ARTICLE.url.pl, { id: articleEn.id, name: articleEn.Url }));
  // } else {
  //   alert("ELSEEE");
  //   // navigate(generatePath(RoutePaths.BLOG.url[lang]));
  // }
  // }, [lang]);

  useEffectAfterMounted(() => {
    const langFromUrl = location.pathname.indexOf("/en/") !== -1 ? "en" : ((location.pathname.indexOf("/pl/") !== -1 ? "pl" : undefined) as "pl" | "en");
    setUrlLang(langFromUrl);
    fetchData();
  }, [location]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HelmetSection />
      <div className="article-page">
        {articleBases ? (
          <>
            {articleBases[urlLang] ? null : <Navigate to={RoutePaths.BLOG.url[urlLang]} />}
            <ArticleHeroSection article={articleBases[urlLang]} />
            {articleBases[urlLang]?.ContentEditor ? <ContentBlocks blocksData={articleBases[urlLang]?.ContentEditor} /> : null}
            {articleBases[urlLang] && articleBases[urlLang]?.related && articleBases[urlLang]?.related?.data && articleBases[urlLang]?.related?.data.length > 0 ? <RelatedListSection articles={articleBases[urlLang]?.related?.data} /> : null}
          </>
        ) : null}
      </div>
    </>
  );
};

export default ArticlePage;
