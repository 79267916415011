import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import './TextSection.scss';

interface TextSectionProps {
}

const TextSection: React.FC<TextSectionProps> = (props) => {

  return (
    <section className="TextSection py-3 pb-5 py-xl-5">
      <Container>
        <Row className="content py-3 py-xl-4">
          <Col size={[8, 10, 12]} offset={[2, 1, 0]}>
            <h2 className="py-4">Polityka prywatności</h2>
          </Col>
          <Col size={[8, 10, 12]} offset={[2, 1, 0]} className="pb-5">
            <h3>Kto jest administratorem moich danych osobowych?</h3>
            <p>Administratorem danych osobowych jest Irmatiq sp. z o.o. z siedzibą w Warszawie przy ulicy Al. Jerozolimskie 44, NIP 7011062043, REGON: 520621053, wpisaną do Rejestru Przedsiębiorc&oacute;w Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla M. St. Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000933644, dalej: administrator lub Irmatiq.</p>
            <h3>Jakie dane osobowe przetwarza Administrator?</h3>
            <p>Irmatiq jako administrator danych osobowych w rozumieniu RODO przetwarza w szczeg&oacute;lności następujące dane osobowe:</p>
            <ul>
              <li><strong>Dane osobowe kandydat&oacute;w do pracy</strong> (dane, kt&oacute;re kandydaci podają administratorowi, w związku z uczestnictwem w procesach rekrutacyjnych - w szczeg&oacute;lności dane zawarte na CV)</li>
              <li><strong>Dane osobowe kontrahent&oacute;w</strong><strong>oraz ich przedstawicieli </strong>(dane firm* oraz os&oacute;b kontaktowych &ndash; podstawowe dane służące do wystawiania oraz księgowania faktur (nazwa, NIP, adres prowadzenia działalności gospodarczej) oraz do kontaktu (imię, nazwisko, nazwa firmy, stanowisko pracy, adres e-mail, numer telefonu))</li>
              <li><strong>Osoby przesyłające zapytania poprzez formularze kontaktowe, pocztę e-mail oraz telefonicznie </strong>(podstawowe dane kontaktowe (imię, nazwisko, adres e-mail) podawane przez osoby w związku z pytaniami przesyłanymi do administratora)</li>
              <li><strong>Osoby, kt&oacute;re zapisały się do usługi Newsletter</strong> (adres e-mail, kt&oacute;ry osoba podaje podczas zapisu do Newslettera)</li>
            </ul>
            <p>* - Dane firm traktowane są jako dane osobowe tylko i wyłącznie w sytuacji, w kt&oacute;rej pozwalają na identyfikację os&oacute;b ich dotyczących. Dane sp&oacute;łek kapitałowych nie są tratowane jak dane osobowe.</p>
            <h3>Na jakich zasadach Administrator przetwarza moje dane osobowe?</h3>
            <p>Poniżej zaprezentowano zasady przetwarzania Państwa danych osobowych (w szczeg&oacute;lności wyjaśniono kto posiada do nich dostęp, jak długo są przechowywane oraz jakie są podstawy ich przetwarzania).</p>
            <p><strong>Dane osobowe kandydat&oacute;w do pracy</strong></p>
            <ul>
              <li><strong>Cele i podstawy przetwarzania danych</strong><strong>:</strong> Państwa dane osobowe będą przetwarzane dla potrzeb procesu rekrutacyjnego lub także dla cel&oacute;w przyszłych proces&oacute;w rekrutacyjnych w przypadku wyrażania przez Państwa stosownej zgody (Podstawą przetwarzania tych danych są obowiązujące regulacje prawne (w szczeg&oacute;lności Kodeks Pracy) oraz udzielona przez Państwa zgoda).</li>
              <li><strong>Odbiorcy danych</strong><strong>:</strong> Dane te mogą być udostępniane podmiotom upoważnionym na podstawie przepis&oacute;w prawa. Dostęp do danych na podstawie stosownych um&oacute;w mogą posiadać także podmioty wspierające Irmatiq w zakresie usług oraz infrastruktury IT.</li>
              <li><strong>Okres przechowywania</strong><strong>:</strong> Przetwarzane przez administratora dane osobowe będą przechowywane do zakończenia procesu rekrutacji lub do czasu odwołania udzielonej przez Państwa zgody w przypadku przetwarzania danych dla cel&oacute;w przyszłych proces&oacute;w rekrutacyjnych.</li>
              <li><strong>Przekazywanie danych poza EOG</strong><strong>:</strong> Irmatiq nie zamierza przekazywać danych osobowych do państw trzecich (czyli państw spoza Europejskiego Obszaru Gospodarczego) ani do jakichkolwiek organizacji międzynarodowych.</li>
              <li><strong>Zautomatyzowane podejmowanie decyzji / profilowanie:</strong> Irmatiq nie podejmuje decyzji opierających się na zautomatyzowanym przetwarzaniu, w tym profilowaniu (w odniesieniu do wyżej opisanych cel&oacute;w przetwarzania danych).</li>
              <li><strong>Dobrowolność / obowiązek podania danych</strong><strong>:</strong> Podanie danych osobowych w zakresie wymaganym przepisami prawa, w szczeg&oacute;lności Kodeksu pracy jest obligatoryjne, w pozostałym przypadku jest dobrowolne.</li>
            </ul>
            <p><strong>Dane osobowe kontrahent&oacute;w oraz ich przedstawicieli</strong></p>
            <ul>
              <li><strong>Cele i podstawy przetwarzania danych:</strong><strong>Dane osobowe kontrahent&oacute;w</strong> będą przetwarzane dla cel&oacute;w realizacji um&oacute;w zawartych pomiędzy stronami (na podstawie 6 ust. 1 lit. b RODO &ndash; przetwarzanie jest niezbędne do wykonania um&oacute;w, kt&oacute;rych są Państwo stroną) oraz wypełnienia obowiązk&oacute;w nałożonych na administratora na podstawie obowiązujących przepis&oacute;w prawa (Na podstawie art. 6 ust. 1 lit. c RODO - przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na administratorze (w szczeg&oacute;lności w zakresie wystawiania i przechowywania faktur VAT), wynikających m. in. z Ustawy o Rachunkowości oraz Ustawy o VAT. <strong>Dane osobowe przedstawicieli kontrahent&oacute;w</strong> będą przetwarzane dla cel&oacute;w kontaktowych, w tym w celu nawiązania i prowadzenia kontakt&oacute;w biznesowych (Na podstawie art. 6 ust. 1 lit. f RODO - uzasadnionego interesu administratora danych jakim jest zapewnienie kontaktu z kontrahentem oraz zapewnienie realizacji um&oacute;w zawartych z kontrahentami) a także dla cel&oacute;w wypełnienia obowiązk&oacute;w nałożonych na administratora na podstawie obowiązujących przepis&oacute;w prawa (art. 6 ust. 1 lit. c RODO). <strong>Dane osobowe kontrahent&oacute;w oraz ich przedstawicieli</strong> mogą być przetwarzane r&oacute;wnież w celu ewentualnego dochodzenia jak i obrony roszczeń (Podstawą przetwarzania danych jest art. 6 ust. 1 lit. f RODO - uzasadnionego interesu administratora danych jakim jest dochodzenie oraz obrona przed roszczeniami).</li>
              <li><strong>Odbiorcy danych:</strong> Dane te mogą być udostępniane podmiotom upoważnionym na podstawie przepis&oacute;w prawa. Dostęp do danych na podstawie stosownych um&oacute;w mogą posiadać także podmioty wspierające Irmatiq w zakresie usług IT (w szczeg&oacute;lności hostingodawcy).</li>
              <li><strong>Okres przechowywania: </strong>Dane osobowe kontrahent&oacute;w oraz ich przedstawicieli będą przechowywane przez okres wymagany na podstawie obowiązujących przepis&oacute;w prawa. Dane kontaktowe przedstawicieli kontrahent&oacute;w przetwarzane dla cel&oacute;w prowadzenia kontakt&oacute;w biznesowych będą przechowywane do czasu wniesienia sprzeciwu wobec przetwarzania tych danych.</li>
              <li><strong>Przekazywanie danych poza EOG</strong><strong>:</strong> Irmatiq nie zamierza przekazywać danych osobowych do państw trzecich (czyli państw spoza Europejskiego Obszaru Gospodarczego) ani do jakichkolwiek organizacji międzynarodowych.</li>
              <li><strong>Zautomatyzowane podejmowanie decyzji / profilowanie:</strong> Irmatiq nie podejmuje decyzji opierających się na zautomatyzowanym przetwarzaniu, w tym profilowaniu (w odniesieniu do wyżej opisanych cel&oacute;w przetwarzania danych).</li>
              <li><strong>Dobrowolność / obowiązek podania danych: </strong>Podanie danych osobowych jest dobrowolne, jednakże niezbędne do prowadzenia kontakt&oacute;w biznesowych z Irmatiq oraz realizacji łączących strony um&oacute;w.</li>
            </ul>
            <p><strong>Osoby przesyłające zapytania poprzez formularze kontaktowe, pocztę e-mail oraz telefonicznie</strong></p>
            <ul>
              <li><strong>Cele i podstawy przetwarzania danych:</strong> Dane osobowe będą przetwarzane w celu udzielenie odpowiedzi zgodnie z celem wskazanym w formularzu kontaktowym, prowadzenia korespondencji elektronicznej, realizacji działań podjętych na Państwa prośbę oraz w dalszym etapie dla cel&oacute;w kontaktowych, w tym w celu nawiązania i prowadzenia kontakt&oacute;w biznesowych &ndash; o ile wyraża Państwo taką wolę (Podstawą przetwarzania danych jest uzasadniony interes administratora danych jakim jest prowadzenie korespondencji elektronicznej oraz realizacja działań podjętych na prośbę os&oacute;b, kt&oacute;rych dane dotyczą).</li>
              <li><strong>Odbiorcy danych:</strong> Dane te mogą być udostępniane podmiotom upoważnionym na podstawie przepis&oacute;w prawa. Dostęp do danych na podstawie stosownych um&oacute;w mogą posiadać także dostawcy infrastruktury oraz usług IT przetwarzający dane na zlecenie Irmatiq</li>
              <li><strong>Okres przechowywania: </strong>Przetwarzane przez administratora dane osobowe będą przechowywane przez okres prowadzenia korespondencji elektronicznej oraz następnie w celach archiwalnych przez okres określony w instrukcji kancelaryjnej administratora.</li>
              <li><strong>Przekazywanie danych poza EOG</strong><strong>:</strong> Irmatiq nie zamierza przekazywać danych osobowych do państw trzecich (czyli państw spoza Europejskiego Obszaru Gospodarczego) ani do jakichkolwiek organizacji międzynarodowych.</li>
              <li><strong>Zautomatyzowane podejmowanie decyzji / profilowanie:</strong> Irmatiq nie podejmuje decyzji opierających się na zautomatyzowanym przetwarzaniu, w tym profilowaniu (w odniesieniu do wyżej opisanych cel&oacute;w przetwarzania danych).</li>
              <li><strong>Dobrowolność / obowiązek podania danych: </strong>Podanie danych osobowych jest dobrowolne, jednakże niezbędne do wymiany korespondencji elektronicznej lub podejmowania działań podjętych na Państwa prośbę.</li>
            </ul>
            <p><strong>Osoby, kt&oacute;re zapisały się do usługi Newsletter</strong></p>
            <ul>
              <li><strong>Cele i podstawy przetwarzania danych</strong><strong>:</strong> Państwa dane osobowe będą przetwarzane dla cel&oacute;w marketingowych -przesyłania informacji handlowych w formie Newslettera pod wskazany adres e-mail (na podstawie art. 6 ust. 1 lit. f - uzasadnionego interesu administratora danych jakim jest marketing bezpośredni, w związku z udzieloną zgodą na przesyłanie informacji handlowych)</li>
              <li><strong>Odbiorcy danych</strong><strong>:</strong> Dane te mogą być udostępniane:
                <ul>
                  <li>podmiotom upoważnionym na podstawie przepis&oacute;w prawa</li>
                  <li>Dostęp do danych na podstawie stosownych um&oacute;w mogą posiadać także dostawcy infrastruktury oraz usług IT (w tym firmy hostingowe i firmy zapewniające rozwiązania w zakresie e-mail marketingu) przetwarzający dane na zlecenie Irmatiq.</li>
                </ul>
              </li>
              <li><strong>Okres przechowywania</strong><strong>:</strong> Państwa dane osobowe będą przechowywane do czasu wniesienia przez Państwa sprzeciwu wobec ich przetwarzania bądź też wycofania zgody na otrzymywanie informacji handlowych</li>
              <li><strong>Przekazywanie danych poza EOG</strong><strong>:</strong> Irmatiq nie zamierza przekazywać danych osobowych do państw trzecich (czyli państw spoza Europejskiego Obszaru Gospodarczego) ani do jakichkolwiek organizacji międzynarodowych.</li>
              <li><strong>Zautomatyzowane podejmowanie decyzji / profilowanie:</strong> Irmatiq nie podejmuje decyzji opierających się na zautomatyzowanym przetwarzaniu, w tym profilowaniu (w odniesieniu do wyżej opisanych cel&oacute;w przetwarzania danych).</li>
              <li><strong>Dobrowolność / obowiązek podania danych</strong><strong>:</strong> Podanie danych osobowych jest dobrowolne, jednakże niezbędne do zapisania się do usługi NEWSLETTER.</li>
            </ul>
            <h3>Jakie prawa przysługują mi w związku z przetwarzaniem przez Irmatiq sp. z o.o. z siedzibą w Warszawie moich danych osobowych?</h3>
            <p>Posiadają Państwo stosownie do sytuacji prawo do:</p>
            <ul>
              <li>żądania dostępu do swoich danych osobowych, ich sprostowania, usunięcia oraz ograniczenia przetwarzania, a także do przenoszenia swoich danych osobowych;</li>
              <li>wycofania udzielonej zgody w dowolnym momencie (w przypadku jej udzielenia). Wycofanie zgody, nie wpływa na zgodność z prawem przetwarzania, kt&oacute;rego dokonano na podstawie zgody przed jej wycofaniem.</li>
              <li>wniesienia sprzeciwu wobec przetwarzania Państwa danych osobowych</li>
              <li>wniesienia skargi do organu nadzorczego (PUODO &ndash; Prezes Urzędu Ochrony Danych Osobowych)</li>
            </ul>
            <h3>Gdzie mogę się skontaktować w związku z pytaniami dotyczącymi przetwarzania moich danych osobowych oraz realizacją przysługujących mi praw?</h3>
            <p><strong>Punkt kontaktowy:</strong> Wszelkie zapytania oraz oświadczenia w zakresie ww. praw należy kierować pod poniższe dane kontaktowe:</p>
            <p><strong>Dane kontaktowe administratora danych:</strong></p>
            <p>Irmatiq sp. z o.o. z siedzibą w Warszawie</p>
            <p>Al. Jerozolimskie 44</p>
            <p>00-024 Warszawa</p>
            <p>Adres e-mail: contact@irmatiq.com</p>
            <p><strong>Dane kontaktowe inspektora ochrony danych:</strong></p>
            <p>IOD Wojciech Byrski</p>
            <p>Adres e-mail: irmatiq@inspektor-danych.info</p>
            <h3>Informacje dotyczące plik&oacute;w cookie</h3>
            <p><strong>Pliki cookie:</strong> Strona internetowa www.irmatiq.com korzysta z plik&oacute;w cookie. Są to niewielkie pliki zapisywane w Państwa przeglądarce internetowej wykorzystywane do następujących cel&oacute;w:</p>
            <ul>
              <li>Optymalizacyjnych - ułatwiają korzystanie ze strony internetowej (np. zapamiętywanie odznaczenia komunikat&oacute;w wyświetlanych na strony internetowej)</li>
              <li>Analitycznych &ndash; tworzenia statystyk, na podstawie kt&oacute;rych Strona internetowa jest w stanie lepiej dostosować jej zawartość do preferencji Użytkownik&oacute;w</li>
              <li>Marketingowych - wyświetlaniu użytkownikom reklam, kt&oacute;re są dostoswane do ich preferencji (na podstawie art. 6 ust. 1 lit. f RODO &ndash; prawnie uzasadnionego interesu administratora danych jakim jest marketing bezpośredni &ndash; w przypadku udzielenia zgody na wykorzystywanie plik&oacute;w cookie)</li>
            </ul>
            <p>Jeżeli nie zgadzają się Państwo na zapisywanie plik&oacute;w Cookie na swoim urządzeniu, prosimy o zmianę ustawiania swojej przeglądarki internetowej lub/i zablokowanie działania zewnętrznych plik&oacute;w cookie w spos&oacute;b opisany w akapicie &bdquo;Pliki cookie zewnętrzne&rdquo; poniżej.</p>
            <p>Ograniczenie stosowania plik&oacute;w Cookie może wpłynąć na niekt&oacute;re funkcjonalności dostępne na stronie www.irmatiq.com</p>
            <p><strong>Pliki cookie zewnętrzne: </strong>Irmatiq wykorzystuje rozwiązania z zakresu plik&oacute;w cookie/analogicznych technologii następujących podmiot&oacute;w zewnętrznych:</p>
            <ul>
              <li>Google Ireland Limited &ndash; Google Adwords, Google Analytics &ndash; dla cel&oacute;w analitycznych oraz reklamowych (wyświetlania reklam Irmatiq w sieci reklamowej Google). Użytkownik może w każdej chwili zablokować wykorzystywanie jego danych w Google Analytics w&nbsp;
                <a href="https://tools.google.com/dlpage/gaoptout">
                  <u>
                    tym miejscu
                  </u>
                </a>
              </li>
              <li>Facebook Inc. &ndash; Facebook Pixel - dla cel&oacute;w analitycznych oraz reklamowych (wyświetlania reklam Irmatiq w sieci reklamowej Facebook). Użytkownik może w każdej chwili zapoznać się z zasadami przetwarzania jego danych, w tym zablokować wykorzystywanie tych danych przez Facebook w&nbsp;
                <a href="https://www.facebook.com/help/568137493302217">
                  <u>
                    tym miejscu
                  </u>
                </a>.
              </li>
              <li>Hotjar Ltd - HotJar - rozwiązanie wykorzystywane dla cel&oacute;w analitycznych (anonimowej analizy zachowań użytkownika na Stronie internetowej). Użytkownik może w każdej chwili zablokować wykorzystywanie jego danych przez HotJar w&nbsp;
                <a href="https://www.hotjar.com/legal/compliance/opt-out/">
                  <u>
                    tym miejscu
                  </u>
                </a>
                .</li>
            </ul>
            <p>Rozwiązania, o kt&oacute;rych mowa powyżej są wykorzystywane w celu dostosowywania reklam do preferencji użytkownik&oacute;w oraz dla cel&oacute;w analitycznych. Rozwiązania te pozwalają śledzić działania Użytkownik&oacute;w w ramach strony internetowej (np. wyświetlenie konkretnej podstrony w ramach witryny). Na tej podstawie Użytkownikowi mogą być wyświetlane reklamy Irmatiq w ramach sieci reklamowej Google / Facebook.</p>
            <p>Więcej informacji na temat plik&oacute;w cookie mogą Państwo znaleźć w sekcji &bdquo;Pomoc&rdquo; w menu przeglądarki internetowej.</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TextSection;