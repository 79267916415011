import React from "react";
import { useLang } from "../../features/lang-switch/useLang";
import TopLink from "../top-link/top-link";
import './ArticleTile.scss';
import PreviewImg from "./preview.svg";

interface ArticleTileProps {
  img: string | number;
  to: string;
  title: string;
  description: string;
  isRelated?: boolean;
}

const ArticleTile: React.FC<ArticleTileProps> = (props) => {
  const { to, img, title, description, isRelated } = props;
  const { T } = useLang();

  return (
    <div className="ArticleTile">
      {isRelated ?
        <a href={to}>
          <div className="preview" style={{ backgroundImage: `url('${img}')` }}>
            <img src={PreviewImg} alt="preview img" />
          </div>
          <div className="content">
            <h3 className="title py-1 py-lg-2 py-xl-3">{title}</h3>
            <p className="description  py-1 py-lg-2 py-xl-3">{description}</p>
          </div>
          <div className="redirect-ala-link py-1 py-lg-2 py-xl-3">
            {T(
              "Czytaj dalej",
              "Read more"
            )}
          </div>
        </a>
        :
        <TopLink to={to}>
          <div className="preview" style={{ backgroundImage: `url('${img}')` }}>
            <img src={PreviewImg} alt="preview img" />
          </div>
          <div className="content">
            <h3 className="title py-1 py-lg-2 py-xl-3">{title}</h3>
            <p className="description  py-1 py-lg-2 py-xl-3">{description}</p>
          </div>
          <div className="redirect-ala-link py-1 py-lg-2 py-xl-3">
            {T(
              "Czytaj dalej",
              "Read more"
            )}
          </div>
        </TopLink>
      }
    </div>
  );
};

export default ArticleTile;
