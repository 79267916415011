import React from "react";
import { useAppSelector } from "../../redux/store";
import { selectLang } from "./langSlice";

export function useLang() {
  const lang = useAppSelector(selectLang);
  const T = (pl: string | React.ReactNode, en: string | React.ReactNode): string | React.ReactNode => {
    return lang === 'pl' ? pl : en;
  };

  return { T, lang };
}