import React from "react";
import './MarkIcon.scss';

const MarkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.866" height="10.065" viewBox="0 0 16.866 12.065">
      <path id="Icon_feather-check" data-name="Icon feather-check" d="M20.038,9l-9.651,9.651L6,14.264" transform="translate(-4.586 -7.586)" fill="none" stroke="#23cd91" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
  )
}

export const MarkIconLarge = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="103" height="103" viewBox="0 0 103 103">
      <g id="markiconlarge" transform="translate(-5980 -7144)">
        <circle id="pexels-andrea-piacquadio-3760378" cx="51.5" cy="51.5" r="51.5" transform="translate(5980 7144)" fill="#fbfbfb" />
        <path id="Icon_feather-check" data-name="Icon feather-check" d="M25.482,55.4,4.232,34.145l3.536-3.536L25.482,48.324,66.574,7.232l3.536,3.536Z" transform="translate(5995.329 7165.07)" fill="#23cd91" />
      </g>
    </svg>

  )
}

export default MarkIcon;
