import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import "./IrmatiqUsage.scss";
import { useLang } from "../../../../features/lang-switch/useLang";
import RainbowTile from "./components/Tile/RainbowTile";

interface IrmatiqBenefitsProps { }

const IrmatiqBenefits: React.FC<IrmatiqBenefitsProps> = (props) => {
  const { T } = useLang();

  const usageTile = (x: string | React.ReactNode, index: number) => {
    return (
      <Col size={[4, 4, 6, 12]} className="py-md-2 py-2" key={index}>
        <RainbowTile isRainbow>
          <div className="paragraph">{x}</div>
        </RainbowTile>
      </Col>
    );
  };
  return (
    <section className="IrmatiqUsage IrmatiqBenefits py-3 pt-4 py-xl-5">
      <Container className="pb-md-5 pb-4">
        <Row className="py-xl-5 pt-4 pb-3">
          <Col size={[10, 10, 10, 12]} offset={[1, 1, 1, 0]} className="section-intro">
            <h2 className="py-xl-3">{T("Co zyskasz dzięki Irmatiq?", "Benefits of Irmatiq")}</h2>
            <p className="py-3">{T("Wiemy czego potrzebujesz. Mamy doświadczenie w prowadzeniu projektów z licznymi grupami interesariuszy, inwestorów i klientów. Pracujemy także z różnymi spółkami i fundacjami, aby dostarczyć wszyskie niezbędne elementy ułatwiające codzienną pracę.", "We know what you need. We have experience in running projects with numerous stakeholder groups, investors and clients. We also work with various companies and foundations to provide all the necessary elements to streamline day-to-day work.")}</p>
          </Col>
        </Row>
        {T(
          <Row gap={8}>
            {[
              <>
                <h3>Ogromna oszczędność czasu</h3>
                <p>Ty i Twój zespół poświęcicie nawet 50% mniej czasu na obsługę inwestorów i administracje. Szczególnie gdy inwestorów liczycie w setkach.</p>
              </>,
              <>
                <h3>Zmniejszenie biurokracji</h3>
                <p>Zaoszczędzony czas dzięki automatyzacji poświęcisz na budowanie biznesu, dbanie o relacje z interesariuszami czy po prostu odpoczynek</p>
              </>,
              <>
                <h3>Obniż koszt pozyskania kapitału</h3>
                <p>Samodzielna realizacja emisji/kampanii, ponowne lub cykliczne pozyskiwanie kapitału od interesariuszy może wpłynąć na oszczędności i przyspieszy proces.</p>
              </>,
              <>
                <h3>Zarządzanie procesem pod kontrolą</h3>
                <p>Zarządzaj relacjami z interesariuszami w sposób ustrukturyzowany. Przydzielaj dostępy w zgodzie z uprawnieniami członków zespołu.</p>
              </>,
              <>
                <h3>Lepsze relacje z inwestorami</h3>
                <p>Lepsza i szybsza obsługa to dobry krok do budowania relacji z inwestorami. A zadowolony inwestor może zostać Twoim ambasadorem marki czy po prostu chętniej zainwestuje ponownie</p>
              </>,
              <>
                <h3>...trochę spokoju i porządku</h3>
                <p>Fundraising i pozyskiwanie kapitału bywa stresujące. Warto ułatwić sobie pracę wygodnym narzędziem, które pomaga ustrukturyzować pracę.</p>
              </>,
            ].map((x, index) => usageTile(x, index))}
          </Row>,
          <Row gap={16}>
            {[
              <>
                <h3>Huge time savings</h3>
                <p>You and your team will spend up to 50% less time on investor management and administration. Especially when your investors number is in the hundreds.</p>
              </>,
              <>
                <h3>Less bureaucracy </h3>
                <p>Spend the time you save through automation on building your business, nurturing stakeholder relationships or simply relaxing</p>
              </>,
              <>
                <h3>Reduce the cost of capital</h3>
                <p>Doing the issue/campaign yourself, reiterating or cyclically raising capital from stakeholders can impact savings and speed up the process.</p>
              </>,
              <>
                <h3>Process under control</h3>
                <p>Manage stakeholder and investor relationship in a structured way. Manage your team access and tasks</p>
              </>,
              <>
                <h3>Better investor relations</h3>
                <p>Better and faster service is a good step towards building relationships with investors. And a happy investor may become your brand ambassador or simply be more likely to invest again</p>
              </>,
              <>
                <h3>...a little peace and order </h3>
                <p>Fundraising can be stressful. It's worth easing your mind with a convenient tool that helps you structure your work.</p>
              </>

            ].map((x, index) => usageTile(x, index))}
          </Row>
        )}
      </Container>
    </section>
  );
};

export default IrmatiqBenefits;
