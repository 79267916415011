import React, { useEffect, useState } from "react";
import "./Button.scss";
import ArrowIcon from "./arrow.svg";

export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'arrow-icon' | 'secondary-inactive';
  size?: 'small' | 'regular' | 'large';
  fitContent?: boolean;
  // dataAos?:string;
  // dataAosDelay?:string;
}

const Button: React.FC<ButtonProps> = (props) => {
  const { children, variant = "primary", size = "regular", fitContent = false } = props;
  const [buttonProps, setButtonProps] = useState<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>();

  useEffect(() => {
    var tempProps = { ...props };
    if (tempProps.hasOwnProperty('children')) delete tempProps.children;
    if (tempProps.hasOwnProperty('variant')) delete tempProps.variant;
    if (tempProps.hasOwnProperty('fitContent')) delete tempProps.fitContent;

    setButtonProps(tempProps);
  }, [props])


  return <button {...buttonProps} className={`button size-${size} button-${variant} ${fitContent ? 'button-fit-content' : ''} ${props.className}`}>{children}{variant === 'arrow-icon' ? <img className="icon" src={ArrowIcon} alt="->" /> : null}</button>;
};

export default Button;
