import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import './CloseToSection.scss';
import BgDisksImg from "./bg-disks.svg";
import PanelImg from "./panel.png";
import { useLang } from "../../../../features/lang-switch/useLang";
import TextBlock from "../../../../libs/text-block/text-block";
import MarkIcon from "../../../../components/MarkIcon/MarkIcon";


interface CloseToSectionProps {
}

const CloseToSection: React.FC<CloseToSectionProps> = (props) => {
  const { T } = useLang();

  return (
    <section className="CloseToSection">
      <Container>
        <Row className="pt-3 reverse-on-mobile">
          <Col size={[6, 6, 6, 12]}>
            <Row className="pt-3 pb-3">
              <Col size={[9, 9, 9, 12]} className="content-col">
                <TextBlock>
                  <h3 className="py-2 py-xl-3">
                    {T(
                      <>Dla kogo jest Irmatiq?</>,
                      <>What kind of organizations is Irmatiq designed for?</>
                    )}
                  </h3>
                  <p className="py-2 py-xl-3">
                    {T(
                      <>
                        <MarkIcon />&nbsp;Spółki akcyjne <br />
                        <MarkIcon />&nbsp;ASI <br />
                        <MarkIcon />&nbsp;spółki po equity crowdfundingu <br />
                        <MarkIcon />&nbsp;projekty nieruchomościowe <br />
                        <MarkIcon />&nbsp;spółki po emisjach prywatnych <br />
                        <MarkIcon />&nbsp;grupy inwestorskie <br />
                        <MarkIcon />&nbsp;emitenci pożyczek <br />
                        <MarkIcon />&nbsp;spółki niepubliczne <br />
                        <br />
                        Porozmawiajmy o Twoich konkretnych potrzebach.
                      </>,
                      <>
                        <MarkIcon />&nbsp;Organizations with equity crowdfunding <br />
                        <MarkIcon />&nbsp;Private companies <br />
                        <MarkIcon />&nbsp;Real estate projects <br />
                        <MarkIcon />&nbsp;Companies after private issue shares <br />
                        <MarkIcon />&nbsp;Investor groups <br />
                        <MarkIcon />&nbsp;Loan issuers <br />
                        <br />
                        Let's talk about your needs
                      </>
                    )}
                  </p>
                </TextBlock>
              </Col>
            </Row>
          </Col>
          <Col size={[6, 6, 6, 12]} className="bg-disks-wrapper">
            <div className="bg-disks" style={{ backgroundImage: `url(${BgDisksImg})` }}>
            </div>
            <img src={PanelImg} alt="Panel" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CloseToSection;
