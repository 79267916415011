import React from "react";
import "./about-page.scss";
import LargeMidTextSectionWithBg from "./sections/LargeMidTextSectionWithBg/LargeMidTextSectionWithBg";
import ContactInfoSection from "./sections/ContactInfoSection/ContactInfoSection";
import TeamSection from "./sections/TeamSection/TeamSection";
import ContactSection from "../home-page/sections/ContactSection/ContactSection";
import HelmetSection from "./sections/HelmetSection/HelmetSection";

const AboutPage: React.FC = () => {
  return (
    <>
      <HelmetSection />
      <div className="about-page">
        <LargeMidTextSectionWithBg />
        {/* <InfoSection /> */}
        <TeamSection />
        <ContactInfoSection />
        <ContactSection />
      </div>
    </>
  );
};

export default AboutPage;
