import React from "react";
import CircleInfoRow from "../../../../components/CircleInfoRow/CircleInfoRow";
import Container from "../../../../libs/grid/container";
import JakubNiestrojImg from "./jakubNiestroj.png"
// import MarcelImg from "./marcel.png"
// import JakubImg from "./jakub.png"
// import WojciechImg from "./wojciech.png"
import './TeamSection.scss';
import { useLang } from "../../../../features/lang-switch/useLang";

interface TeamSectionProps {
}

const TeamSection: React.FC<TeamSectionProps> = (props) => {
  const { T } = useLang();
  return (
    <section className="TeamSection my-4 my-xl-0 pb-4 py-xl-5">
      <Container className="py-4 py-xl-5">
        <CircleInfoRow
          icon={undefined}
          img={JakubNiestrojImg}
          size="small"
        >
          <h3>Jakub Niestrój, Ph.D</h3>
          <p>CEO</p>
          <p className="py-2 py-xl-3">
            {T(
              "Menedżer i inwestor z międzynarodowym doświadczeniem w obszarze konsultingu i rozwoju biznesu. Aktualnie partner zarządzający Funduszu AVERTON STERLING ASI oraz główny udziałowiec i prezes zarządu IRMATIQ sp. z o.o",
              "Manager and investor with International experience in consulting and business development. Currently, managing partner at the AVERTON STERLING ASI Fund and the main shareholder and CEO of Irmatiq."
            )}
          </p>
        </CircleInfoRow>
      </Container>
    </section>
  );
};

export default TeamSection;
