import React from "react";
import "./menu-btn.scss";

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

const MenuBtn: React.FC<Props> = (props: Props) => {
  return (
    <div className="menu-btn-wrapper" onClick={props.onClick}>
      <div className={`menu-btn ${props.isOpen ? "open" : ""}`}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default MenuBtn;
