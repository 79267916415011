import React, { useEffect, useState } from "react";
import "./simple-transition.scss";
import { selectSimpleTransition, hideSlideOverlay } from "./simpleTransitionSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";

const SimpleTransition: React.FC = () => {
  const [overlay, setOverlay] = useState(false);
  const [opacity, setOpacity] = useState(false);
  const simpleTransition = useAppSelector(selectSimpleTransition);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (simpleTransition.slideOverlay.isActive === true) {
      show();
    } else {
      hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simpleTransition]);

  const show = () => {
    setOverlay(true);
    setOpacity(true);

    setTimeout(() => {
      dispatch(hideSlideOverlay());
    }, 200);
  };

  const hide = () => {
    setOpacity(false);
    setTimeout(() => {
      setOverlay(false);
    }, 200);
  };

  return (
    <div className={`simple-transition ${overlay ? "active" : ""}`}>
      <div className={`simple-transition__opacity ${opacity ? "active" : ""}`} />
    </div>
  );
};

export default SimpleTransition;
