import React from "react";
import { useLang } from "../../../features/lang-switch/useLang";
import Button from "../../../libs/buttons/Button/Button";
import "./CallBtn.scss";

interface CallBtnProps {
  className?: string;
  size?: "small" | "regular" | "large";
  children?: React.ReactNode;
}

const CallBtn: React.FC<CallBtnProps> = (props) => {
  const { className = "", size = "regular", children } = props;
  const { T } = useLang();

  return (
    <a href="mailto:contact@irmatiq.com" target="_blank" className={className} rel="noreferrer">
      <Button size={size}>{children ? children : T("Porozmawiajmy", "Let's talk")}</Button>
    </a>
  );
};

export default CallBtn;
