import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import "./IrmatiqUsage.scss";
import { useLang } from "../../../../features/lang-switch/useLang";
import RainbowTile from "./components/Tile/RainbowTile";
import CirclesImg from "./circles.svg";

interface IrmatiqUsageProps { }

const IrmatiqUsage: React.FC<IrmatiqUsageProps> = (props) => {
  const { T } = useLang();

  const usageTile = (x: string | React.ReactNode, index: number) => {
    return (
      <Col size={[4, 4, 6, 12]} className="py-md-2 py-2" key={index}>
        <RainbowTile isRainbow>
          <p>{x}</p>
        </RainbowTile>
      </Col>
    );
  };
  return (
    <section className="IrmatiqUsage py-3 pt-4 py-xl-5 bg-circles-gradient" style={{ backgroundImage: `url(${CirclesImg})` }}>
      <Container className="pb-md-5 pb-4">
        <Row className="py-xl-5 pt-4 pb-3">
          <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]} className="section-intro">
            <h2 className="py-xl-3">{T("Do czego wykorzystasz Irmatiq?", "What can you use the Irmatiq for?")}</h2>
            <p className="py-3 secondary">{T("#fundraising #crowdfunding #crowdlending #nieruchomości #inwestycjeprywatne #fundacja #NGO", "#fundraising #crowdfunding #crowdlending #realEstate #privateInvestments #foundation #NGOs")}</p>
          </Col>
        </Row>
        {T(
          <Row gap={8}>
            {[
              "Komunikacja i automatyzacja procesów w spółkach, które realizują emisje crowdinvestingowe lub zbiórki crowdfundingowe. Także NGO.",
              "Przeprowadzenie emisji publicznej lub prywatnej opartej o akcje, udziały lub pożyczki.",
              "Raportowanie do rozproszonej grupy interesariuszy jak inwestorzy, darczyńcy, sponsorzy, wspierający, subskrybenci.",
              "Zbiórki (w tym cykliczne/subskrypcyjne) od społeczności klientów, darczyńców czy fanów.",
              "Zarządzanie dokumentacją związaną z projektem czy inwestycją. Ułatwienie obiegu dokumentów, podpisywania online.",
              "Budowanie relacji z inwestorami, darczyńcami, sybskrybentami - jednym słowem interesariuszami",
              "Automatyzacja procesów związanych z finansowaniem: cykliczna wypłata odsetek, dywidendy czy spłata zobowiązania.",
              "Szybki podgląda statystyk związanych z finansowaniem oraz komunikacją z interesariuszami.",
              "Podziel się swoim wyzwaniem - postaramy się pomóc :)"
            ].map((x, index) => usageTile(x, index))}
          </Row>,
          <Row gap={16}>
            {[
              "Communication and automation of processes in companies that carry out crowdinvesting issues or crowdfunding collections. Also NGOs.",
              "Raise funds with equity crowdfunding or crowdlending.",
              "Report to a distributed group of stakeholders like investors, donors, sponsors, supporters, subscribers.",
              "Fundraising (including recurring) from a community of clients, donors or fans.",
              "Document management for a project or investment. Facilitate document flow and online signing.",
              "Build relationships with investors, donors or subscribers - in a nutshell, stakeholders.",
              "Automation of financing processes: recurring payment of interest, dividends or debt repayments.",
              "A quick overview of statistics related to funding and stakeholder communication.",
              "Share your problems or challenges with us - we will try to help :)"
            ].map((x, index) => usageTile(x, index))}
          </Row>)}
      </Container>
    </section>
  );
};

export default IrmatiqUsage;
