import React from "react";
import { useLang } from "../../../../features/lang-switch/useLang";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import './ContactInfoSection.scss';

interface ContactInfoSectionProps {
}

const ContactInfoSection: React.FC<ContactInfoSectionProps> = (props) => {
  const { T } = useLang();

  return (
    <section className="ContactInfoSection py-3 py-xl-5 mb-xl-5">
      <Container>
        <Row className="pt-3 pb-5 py-xl-5">
          <Col size={[6, 6, 6, 12]} className="map-wrapper-col">
            <div className="map-wrapper">
              <div className="map-shadow" />
              <iframe id="map" title="Irmatiq Spółka z o.o Widok Towers, p. X Al. Jerozolimskie 44 00-024 Warszawa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1703.190839780229!2d21.01130303333996!3d52.23047646924535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecde194b9d93f%3A0x54998ad92df3169e!2sWidok%20Towers!5e0!3m2!1spl!2spl!4v1648003821112!5m2!1spl!2spl" loading="lazy"></iframe>
            </div>
          </Col>
          <Col size={[6, 6, 6, 12]}>
            <Row className="pt-3 pb-3">
              <Col size={[9, 9, 9, 12]}>
                <h2>
                  {T(
                    "Jak możesz nas znaleźć?",
                    "Where can you find us?"
                  )}
                </h2>
              </Col>
            </Row>
            <Row className="content py-3 py-xl-4">
              {
                [
                  <>
                    Irmatiq Spółka z o.o <br />
                    Widok Towers, p. X <br />
                    Al. Jerozolimskie 44 <br />
                    00-024 Warszawa
                  </>,
                  <>
                    hello@irmatiq.com <br />
                    +48 987 651 423
                  </>,
                  <>
                    {T(
                      "Jak nas znaleźć?",
                      "How to find us: "
                    )}
                    <br />
                    <a href="https://www.google.pl/maps/place/Widok+Towers/@52.2304765,21.011303,17.52z/data=!4m5!3m4!1s0x471ecde194b9d93f:0x54998ad92df3169e!8m2!3d52.2303379!4d21.0129795" className="primary">Google Maps</a>
                  </>,
                  <>
                    NIP 7011062043 <br />
                    KRS 0000933644 <br />
                    REGON 520621053
                  </>
                ].map((x: string | React.ReactNode, index: number) => {
                  return (
                    <Col key={index} size={[5, 6, 6, 6, 12]} offset={index % 2 === 1 ? [1, 0] : undefined}>
                      <p className="py-2 pr-3 py-lg-3 my-xl-3 pr-lg-4">{x}</p>
                    </Col>
                  )
                })
              }
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactInfoSection;
