import { Outlet } from "react-router-dom";
import CookiePopup from "../components/CookiePopup/CookiePopup";
import Footer from "../components/footer/footer";
import Navbar from "../components/navbar/navbar";
import Prefooter from "../components/prefooter/prefooter";
import RouterTransition from "../components/router-transition/router-transition";
import SimpleTransition from "../components/simple-transition/simple-transition";
import "./layout.scss";

function Layout() {

  return (
    <>
      <RouterTransition />
      <SimpleTransition />
      <div className="layout">
        <div className="main">
          <Navbar />
          <div className="page-wrapper">
            <Outlet />
          </div>
          <Prefooter />
          <Footer />
          <CookiePopup />
        </div>
      </div>
    </>
  );
}

export default Layout;