import React from "react";
import "./blog-page.scss";
import BlogHeroSection from "./sections/BlogHeroSection/BlogHeroSection";
import BlogListSection from "./sections/BlogListSection/BlogListSection";
import HelmetSection from "./sections/HelmetSection/HelmetSection";

const BlogPage: React.FC = () => {

  return (
    <>
      <HelmetSection />
      <BlogHeroSection />
      <BlogListSection />
    </>
  );
};

export default BlogPage;
