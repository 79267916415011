import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import { useLang } from "../../../../features/lang-switch/useLang";
import TakeFlowImg from "./irmatiq_narzedzie_2-1.svg";
import TakeFlowImgEng from "./irmatiq_narzedzie_eng.svg";
import "./TakeFlowSection.scss";

interface TakeFlowSectionProps {}

const TakeFlowSection: React.FC<TakeFlowSectionProps> = (props) => {
  const { T } = useLang();

  return (
    <section className="TakeFlowSection py-5">
      <Container className="py-5">
        <Row className="pt-3 pb-3">
          <Col size={[10, 10, 10, 12]} offset={[1, 1, 1, 0]} className="section-intro">
            <h2 className="py-xl-3">{T(<>Kompleksowe narzędzie do fundrisingu oraz zarządzania relacjami z wieloma interesariuszami.</>, <>One-stop shop for fundraising and multi-stakeholder relationship management</>)}</h2>
            <p className="py-3">{T("Szybkie wdrożenie, łatwa obsługa, stworzone zgodnie z obowiązującymi regulacjami prawnymi.", "Quick to implement, easy to use, innovative")}</p>
          </Col>
        </Row>
        <Row>
          <Col size={[10, 10, 12, 12]} offset={[1, 1, 0, 0]}>
            {T(<img src={TakeFlowImg} alt="Irmatiq" data-aos="zoom-in" />, <img src={TakeFlowImgEng} alt="Irmatiq" data-aos="zoom-in" />)}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TakeFlowSection;
