import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import "./LargeMidTextSectionWithBg.scss";
import CirclesImg from "./circles.svg";
import { useLang } from "../../../../features/lang-switch/useLang";

interface LargeMidTextSectionWithBgProps {}

const LargeMidTextSectionWithBg: React.FC<LargeMidTextSectionWithBgProps> = (props) => {
  const { T } = useLang();

  return (
    <section className="LargeMidTextSectionWithBg py-3 my-3">
      <Container>
        <div className="section-bg py-0 pt-5 py-xl-5" style={{ backgroundImage: `url(${CirclesImg})` }}>
          <Row className="py-4">
            <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
              <div className="px-lg-4 px-md-2 px-0 px-xl-5">
                <h1 className="h1">{T(<>Niższe koszty, łatwa obsługa, oszczędność czasu i dobre relacje z inwestorami</>, <>Lower your costs, save time, and build good relationships with your investors</>)}</h1>
                <p className="pt-4">{T(<>Postaw na nowoczesne narzędzie do profesjonalnego zarządzania inwestorami w spółce</>, <>Choose a comprehensive, yet easy-to-use tool, to manage your company's investors professionally</>)}</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default LargeMidTextSectionWithBg;
