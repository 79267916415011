import { fetchWrapper } from "../../api/fetchWrapper"

export interface ArticleBaseModel {
  id: string,
  locale: string,
  Url: string,
}

export interface ArticleModel {
  id: string,
  locale: string,
  Url: string,
  Title: string,
  Date: any,
  PreviewImage: any,
  Description: string,
  ContentEditor: any;
  related: any;
  categories: any;
  author: any;
}

export const fetchArticleBase = async (id = ""): Promise<Array<ArticleBaseModel>> => {

  // var filter = "filters[Url][$eq]=" + url;
  // var idFilter = "filters[CommonId][$eq]=" + CommonId;
  var idFilter = "filters[id][$eq]=" + id;
  // var idFilter = "id=" + CommonId;

  // https://api.irmatiq.com/api/articles?filters[id][$eq]=7&populate[localizations][populate]=en&locale=all

  var fields: Array<string> = [    // "sort=PriorityOrder:DESC", // sort
    "populate[author][populate]=*",
    "populate[PreviewImage][populate]=*",
    "populate[categories][populate]=*",
    "populate[localizations][populate]=*",
    "populate[ContentEditor][populate]=*",
    "populate[related][populate]=*",
    "populate[localizations][populate]=all",
    "populate=*",
    idFilter
    // filter,
  ]

  var articlesBases: Array<ArticleBaseModel> = [];
  await fetchWrapper(`/api/articles?`, fields, 'all')
    .then((res: any) => {
      if (res && res.length > 0) {
        articlesBases.push(selectArticleBase(res[0]));
        var localizationsData = res[0].attributes.localizations?.data;
        if (localizationsData?.length > 0) {
          for (let index = 0; index < localizationsData.length; index++) {
            const element = localizationsData[index];
            articlesBases.push(selectArticleBase(element));
          }
        }
        return articlesBases;
      }
    })
    .catch((err) => console.log(err));

  return articlesBases;
}


export const fetchSingleArticle = async (id = ""): Promise<ArticleModel | null> => {
  var idFilter = "filters[id][$eq]=" + id;

  var fields: Array<string> = [
    "populate[author][populate]=*",
    "populate[PreviewImage][populate]=*",
    "populate[categories][populate]=*",
    "populate[localizations][populate]=*",
    "populate[ContentEditor][populate]=*",
    "populate[related][populate]=*",
    "populate[localizations][populate]=all",
    "populate=*",
    idFilter
  ]

  var article = null;

  await fetchWrapper(`/api/articles?`, fields, 'all')
    .then((res: any) => {
      if (res && res.length > 0) {
        article = selectArticle(res[0])
      }
    })
    .catch((err) => console.log(err));

  return article;
}


// populate[author][populate]=*&populate[PreviewImage][populate]=*&filters[Url][$eq]=dolor-septum-pl&locale=pl

// https://api.irmatiq.com/api/articles/7?filters[id][$eq]=1&populate[localizations][populate]=all&locale=all

// https://api.irmatiq.com/api/articles?filters[id][$eq]=1&populate[localizations][populate]=all&locale=all


const selectArticleBase = (data: any): ArticleModel => {
  return {
    id: data.id,
    locale: data.attributes.locale,
    Url: data.attributes.Url,
  } as ArticleModel
}

const selectArticle = (data: any): ArticleModel => {

  return {
    id: data.id,
    locale: data.attributes.locale,
    Url: data.attributes.Url,
    Title: data.attributes.Title,
    Date: data.attributes.Date,
    PreviewImage: data.attributes.PreviewImage,
    Description: data.attributes.Description,
    ContentEditor: data.attributes.ContentEditor,
    related: data.attributes.related,
    categories: data.attributes.categories,
    author: data.attributes.author
  } as ArticleModel
}