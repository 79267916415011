import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import "./CloseToSection.scss";
import BgDisksImg from "./bg-disks.svg";
import PanelImg from "./panel.png";
import { useLang } from "../../../../features/lang-switch/useLang";
import TextBlock from "../../../../libs/text-block/text-block";

interface CloseToSectionProps {}

const CloseToSection: React.FC<CloseToSectionProps> = (props) => {
  const { T } = useLang();

  return (
    <section className="CloseToSection">
      <Container>
        <Row className="pt-3 reverse-on-mobile">
          <Col size={[6, 6, 6, 12]}>
            <Row className="pt-3 pb-3">
              <Col size={[9, 9, 9, 12]} className="content-col">
                <TextBlock>
                  <h3 className="py-2 py-xl-3">{T("Bądź blisko swoich inwestorów", "Stay close to your investors")}</h3>
                  <p className="py-2 py-xl-3">{T("Posiadanie wielu inwestorów wymaga odpowiedniej organizacji. Aby zapewnić najwyższą efektywność komunikacji z nimi, stworzyliśmy narzędzie z myślą o prywatnych spółkach", "Organization is necessary when a company has a lot of investors. To achieve the highest level of communication effectiveness with them, we have developed a tool designed for private companies")}</p>
                  <p className="py-2 py-xl-3">
                    <b>{T("Optymalizuj komunikację z inwestorami", "Optimize communication with your investors")}</b>
                    <br />
                    {T(
                      <>
                        Oszczędzaj czas poświęcany na obsługę inwestorów dzięki automatyzacji
                        <br />
                        Bądź w stałym kontakcie z inwestorami, zadbaj o dobrą relację z nimi i zwiększ szansę twojej spółki na sukces kolejnej emisji akcji lub długu
                      </>,
                      <>
                        Start saving time handling your investors with automation
                        <br />
                        Maintain open lines of communication with your investors, build good relationships with them, and benefit from higher chances of success with your company's next equity or debt issue
                      </>
                    )}
                  </p>
                  <p className="py-2 py-xl-3">
                    <b>{T("Zastąp wszystkie aplikacje jednym narzędziem", "Replace all of your IR tools with a single all-in-one app")}</b>
                    <br />
                    {T(
                      <>
                        Odłóż na bok niezliczone foldery i arkusze na rzecz kompleksowego rozwiązania od Irmatiq
                        <br />
                        Poznaj wszystkie funkcje i dowiedz się, jak wykorzystać je w Twojej spółce
                      </>,
                      <>
                        Set aside all those countless folders and sheets and use this comprehensive solution from Irmatiq
                        <br />
                        Explore our features and see how you can use them in your own organization
                      </>
                    )}
                  </p>
                </TextBlock>
              </Col>
            </Row>
          </Col>
          <Col size={[6, 6, 6, 12]} className="bg-disks-wrapper">
            <div className="bg-disks" style={{ backgroundImage: `url(${BgDisksImg})` }}></div>
            <img src={PanelImg} alt="Panel" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CloseToSection;
