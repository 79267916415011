import { fetchWrapper } from "../../api/fetchWrapper";

export function fetchArticles(lang = "pl", category?: string) {
  var fields = [
    // "sort=PriorityOrder:DESC", // sort
    "sort=createdAt:DESC",
  ];

  if (category) {
    var categoryFilter = "filters[categories][url][$eq]=" + (category ?? "*");
    fields.push(categoryFilter);
  }
  fields.push("populate=*");

  return fetchWrapper("/api/articles?", fields, lang);
}
