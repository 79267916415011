import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import './RelatedListSection.scss';
import { useLang } from "../../../../features/lang-switch/useLang";
import ArticleTile from "../../../../components/ArticleTile/ArticleTile";
import { generatePath } from "react-router-dom";
import { RoutePaths } from "../../../../route-paths";

interface RelatedListSectionProps {
  articles: Array<any>;
}

const RelatedListSection: React.FC<RelatedListSectionProps> = (props) => {
  const { T, lang } = useLang();
  const { articles } = props;

  return (
    <section className="RelatedListSection mb-5 py-3 py-xl-5">
      <Container>
        <Row className="pt-3" align="center">
          <Col size={12}>
            <h1 className="h1 primary">{T("Przeczytaj również", "Read more")}</h1>
          </Col>
        </Row>
        <Row className="py-3 py-xl-5" gap={32}>
          {articles.map((article: any, index: number) => {
            var attr = article.attributes;
            return (
              <Col size={[4, 4, 6, 12]} className="py-4 py-xl-5" key={index} >
                <ArticleTile
                  isRelated={true}
                  img={attr.PreviewImage?.data?.attributes?.url}
                  to={generatePath(RoutePaths.ARTICLE.url[lang], { id: article.id, name: attr.Url })}
                  title={attr.Title}
                  description={attr.Description}
                />
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  );
};

export default RelatedListSection;