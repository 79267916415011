import React from "react";
import Col from "../../libs/grid/col";
import Container from "../../libs/grid/container";
import Row from "../../libs/grid/row";
import './footer.scss';
import TopLink from "../top-link/top-link";
import { RoutePaths } from "../../route-paths";
import { useLang } from "../../features/lang-switch/useLang";

const Footer: React.FC = () => {
  const { T, lang } = useLang();

  return (
    <footer className="footer pt-xl-5">
      <Container className="py-4 py-xl-4">
        <Row>
          <Col size={[6, 6, 4, 12]}>
            Copyright © 2022 Irmatiq Sp z o.o. All rights reserved.
          </Col>
          <Col size={[6, 6, 8, 12]} className="info-nav text-right">
            <TopLink to={RoutePaths.RULES.url[lang]}>
              {T(
                "Polityka prywatności",
                "Privacy Policy"
              )}
            </TopLink>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
