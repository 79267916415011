import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import "./BlogHeroSection.scss";
import HeroImg from "./section-hero-img.svg";
import { useLang } from "../../../../features/lang-switch/useLang";

interface BlogHeroSectionProps {}

const BlogHeroSection: React.FC<BlogHeroSectionProps> = (props) => {
  const { T } = useLang();

  return (
    <section className="BlogHeroSection mt-xl-5 pt-0 pt-xl-5 pb-0 pb-xl-2">
      <Container>
        <Row className="py-5 reverse-on-mobile" align="center">
          <Col size={[6, 6, 6, 12]} offset={[0, 0, 0, 0]}>
            <div>
              <h1 className="h1">
                {T(
                  <>
                    Korzystaj z naszej <span className="secondary">wiedzy.</span> Rozwijaj się i pracuj lepiej.
                  </>,
                  <>
                    Get the best private market <span className="secondary">insights and tips </span>
                  </>
                )}
              </h1>
              <p className="pt-4 pr-xl-5">{T(<>Dzielimy się wiedzą dotyczącą najświeższych wiadomości, crowdfundingu, crowdlendingu, relacji inwestorskich i prywatnego rynku inwestycji.</>, <>We share with you our knowledge on the latest news, crowdfunding, crowdlending, investor relations and the private investment market.</>)}</p>
            </div>
          </Col>
          <Col size={[5, 5, 6, 12]} offset={[1, 1, 0]} className="img-col">
            <img src={HeroImg} alt="Blog" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BlogHeroSection;
