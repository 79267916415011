import React from "react";
import { useLang } from "../../../../features/lang-switch/useLang";
import Button from "../../../../libs/buttons/Button/Button";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import './LargeMidTextSectionWithBgBottom.scss';
import WorldImg from "./world.png";

interface LargeMidTextSectionWithBgBottomProps {
}

const LargeMidTextSectionWithBgBottom: React.FC<LargeMidTextSectionWithBgBottomProps> = (props) => {
  const { T } = useLang();

  return (
    <section className="LargeMidTextSectionWithBgBottom my-xl-5">
      <Container>
        <div className="section-bg py-0 pt-5 py-xl-5" style={{ backgroundImage: `url(${WorldImg})` }}>
          <Row className="py-5 my-5">
            <Col size={[10, 10, 10, 12]} offset={[1, 1, 1, 0]}>
              <div className="px-lg-4 px-md-2 px-0 px-xl-5">
                <h1 className="h1">
                  {T(
                    "Zapewnij Twojej spółce dobre relacje z inwestorami",
                    "Maintain good relationships with your investors"
                  )}
                </h1>
                <p className="pt-2 pb-4">
                  {T(
                    "Opowiedz nam o potrzebach spółki i przekonaj się, w jaki sposób Irmatiq pomoże Ci wnieść komunikację z inwestorami na wyższy poziom",
                    "Let us know about your specific needs and see how Irmatiq can help you take your investor communications to the next level"
                  )}
                </p>
                <div className="d-flex">
                  <a href="mailto:contact@irmatiq.com" className="m-auto" target="_blank" rel="noreferrer">
                    <Button
                      variant="secondary"
                    >
                      {T(
                        "LET'S CONNECT",
                        "LET'S CONNECT"
                      )}
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default LargeMidTextSectionWithBgBottom;