import React, { useEffect, useState } from "react";
import TopLink from "../../../../components/top-link/top-link";
import { useLang } from "../../../../features/lang-switch/useLang";
import Button from "../../../../libs/buttons/Button/Button";
import CheckBox from "../../../../libs/forms/CheckBox/CheckBox";
import Form from "../../../../libs/forms/Form/Form";
import TextInput from "../../../../libs/forms/TextBox/TextInput";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import { RoutePaths } from "../../../../route-paths";
import "./ContactSection.scss";

interface ContactSectionProps {}

let timeoutForm: any = undefined;
const ContactSection: React.FC<ContactSectionProps> = (props) => {
  const { T, lang } = useLang();
  const [formStatus, setFormStatus] = useState("normal" as "normal" | "pending" | "success" | "fail");

  const onSubmit = (data: { contactForm_fullName: string; contactForm_companyName: string; contactForm_email: string; contactForm_phone: string; contactForm_rules: boolean; contactForm_marketing: boolean }) => {
    var payload = {
      ...data,
      contactForm_rules: data.contactForm_rules ? "TAK" : "NIE",
      contactForm_marketing: data.contactForm_marketing ? "TAK" : "NIE",
    };

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    setFormStatus("pending");

    var result = fetch("./send.php", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });

    result
      .then((response) => {
        if (response.ok) {
          setFormStatus("success");
          const form = document.getElementById("contactForm") as any;
          form.reset();
        } else {
          setFormStatus("fail");
        }
      })
      .catch((error) => {
        setFormStatus("fail");
      });
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutForm);
    };
  }, []);

  useEffect(() => {
    if (formStatus === "success" || formStatus === "fail") {
      timeoutForm = setTimeout(() => {
        setFormStatus("normal");
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStatus]);

  return (
    <>
      <section className="ContactSection py-xl-5">
        <div id="contactFormSection"></div>
        <Container className="py-5">
          <Row>
            <Col size={12}>
              <h2 className="text-center pb-4 pb-xl-5">{T("Skontaktuj się z nami", "Feel free to contact us")}</h2>
            </Col>
          </Row>
          <Row className="pt-xl-3">
            <Col size={[8, 10, 12]} offset={[2, 1, 0]}>
              <Form id="contactForm" onSubmit={(data: any) => onSubmit(data)}>
                <Row>
                  <Col size={[6, 6, 6, 6]} className="py-1 py-xl-3">
                    <TextInput id="contactForm_fullName" label={T("Imię i nazwisko", "Full name")} onChange={(value) => {}} />
                  </Col>
                  <Col size={[6, 6, 6, 6]} className="py-1 py-xl-3">
                    <TextInput id="contactForm_companyName" label={T("Nazwa firmy", "Company name")} onChange={(value) => {}} />
                  </Col>
                  <Col size={[6, 6, 6, 6]} className="py-1 py-xl-3">
                    <TextInput id="contactForm_email" type="email" label="Email" onChange={(value) => {}} required />
                  </Col>
                  <Col size={[6, 6, 6, 6]} className="py-1 py-xl-3">
                    <TextInput id="contactForm_phone" label={T("Telefon", "Phone")} onChange={(value) => {}} />
                  </Col>
                  <Col size={[12, 12, 12, 12]}>
                    <CheckBox id="contactForm_rules" onChange={(value) => {}} required label={T(<>Wyrażam zgodę na przetwarzanie moich danych osobowych, zawartych w niniejszym formularzu, w celu przetworzenia zgłoszenia oraz uzyskania odpowiedzi.</>, <>I consent to the processing of my personal data contained in this form in order to the purpose of processing your request and obtaining a response.</>)} />
                    <CheckBox id="contactForm_marketing" onChange={(value) => {}} label={T(<>Wyrażam zgodę na otrzymywanie od Irmatiq sp. z o.o. z siedzibą w Warszawie informacji handlowych w formie Newslettera na podany w formularzu adres e-mail.</>, <>I agree to receive from Irmatiq sp. z o.o. based in Warsaw information Newsletter to the e-mail address given in the form.</>)} />
                    <p className="form-info">
                      {T(
                        <>
                          Ważne informacje:
                          <br />
                          - W ramach wysyłania informacji handlowych będą wykorzystywane Państwa telekomunikacyjne urządzenia końcowe (np. telefon/komputer) oraz automatyczne systemy wywołujące w rozumieniu ustawy z dnia 16 lipca 2004 roku - Prawo telekomunikacyjne (tj.Dz.U.2021.576 z późn. zm.). <br />
                          Administratorem Państwa danych osobowych jest Irmatiq sp. z o.o. z siedzibą w Warszawie przy ulicy Al. Jerozolimskie 44. Państwa dane osobowe będą przetwarzane w celu prowadzenia korespondencji elektronicznej, realizacji działań podjętych na Państwa prośbę, a w przypadku wyrażenia zgody również dla celów otrzymywania informacji handlowych a także do nawiązania i prowadzenia kontaktów biznesowych - o ile wyrażą Państwo taką wolę. Pełna informacja dotycząca przetwarzania danych osobowych dostępna jest <TopLink to={RoutePaths.RULES.url[lang]}>tutaj</TopLink>.
                        </>,
                        <>
                          Important Information: <br />
                          - Your telecommunications terminal equipment (e.g. telephone/computer) and automatic calling systems within the meaning of the Telecommunications Law of 16 July 2004 (i.e. Journal of Laws 2021.576 as amended) will be used in the sending of commercial information. <br />
                          The administrator of your personal data is Irmatiq sp. z o.o. with its registered office in Warsaw at Al. Jerozolimskie 44. Your personal data will be processed for the purpose of electronic correspondence, carrying out the activities undertaken at your request, and, in case of your consent, also for the purpose of receiving commercial information as well as establishing and conducting business contacts - if you express such a will. Full information on personal data processing is available <TopLink to={RoutePaths.RULES.url[lang]}>here</TopLink>.
                        </>
                      )}
                    </p>
                  </Col>

                  <Col size={[6, 6, 6, 12]} offset={[3, 3, 3, 0]}>
                    <Button disabled={formStatus === "pending"} className="w-100 mt-4" type="submit" variant="secondary" size="large">
                      {T("WYŚLIJ", "SUBMIT")}
                    </Button>
                    <p className="p-status">
                      {formStatus === "pending" ? T("TRWA WYSYŁANIE", "PENDING") : null}
                      {formStatus === "success" ? T("WYSŁANO POMYŚLNIE", "SUCCESS") : null}
                      {formStatus === "fail" ? T("WYSTĄPIŁ PROBLEM PRZY WYSYŁCE", "UNEXPECTED ERROR, MESSAGE NOT SENT") : null}
                    </p>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ContactSection;
