import React from "react";
import "./industries-page.scss";
import InfoSection from "./sections/InfoSection/InfoSection";
import LargeMidTextSectionWithBg from "./sections/LargeMidTextSectionWithBg/LargeMidTextSectionWithBg";
import TilesSection from "./sections/TilesSection/TilesSection";
import InfoSectionGrid from "./sections/InfoSectionGrid/InfoSectionGrid";
import ContactSection from "../home-page/sections/ContactSection/ContactSection";
import HelmetSection from "./sections/HelmetSection/HelmetSection";

const IndustriesPage: React.FC = () => {
  return (
    <>
      <HelmetSection />
      <div className="industries-page">
        <LargeMidTextSectionWithBg />
        <TilesSection />
        <InfoSection />
        <InfoSectionGrid />
        <ContactSection />
      </div>
    </>
  );
};

export default IndustriesPage;
