import React from "react";
import ReactDOM from "react-dom";
import "./styles/_style.scss";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import PixelPerfect from "./features/pixel-perfect/pixel-perfect";
import RouterController from "./components/router-controller/router-controller";
import "aos/dist/aos.css";

var rootElement = document.getElementById("root");

var nodes = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistStore(store)}>
          <HelmetProvider>
            <BrowserRouter>
              <RouterController>
                <PixelPerfect />
                <App />
              </RouterController>
            </BrowserRouter>
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}

if (rootElement && rootElement.hasChildNodes()) {
  ReactDOM.hydrate(nodes(), rootElement);
} else {
  ReactDOM.render(nodes(), rootElement);
}

serviceWorker.unregister();