import React from "react";
import { useLang } from "../../../../features/lang-switch/useLang";
import { Helmet } from "react-helmet-async";

const HelmetSection: React.FC = () => {
  const { T } = useLang();

  return (
    <Helmet>
      <meta name="Description" content={
        `${T(
          "Zarządzaj inwestorami z poziomu aplikacji, oszczędzaj czas i zbieraj środki w łatwiejszy sposób",
          "Manage all your company's investors with just one app, save time, and make it easier to gain more funding"
        )}`
      } />
      <title>
        {T(
          "Irmatiq",
          "Irmatiq"
        )}
      </title>
    </Helmet>
  );
};

export default HelmetSection;
