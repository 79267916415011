import React from "react";
import "./OpinionTile.scss";

interface OpinionTileProps {
  children: React.ReactNode;
  quote: React.ReactNode | string;
  icon?: string;
  footer?: React.ReactNode;
  className?: string;
}

const OpinionTile: React.FC<OpinionTileProps> = (props) => {
  const { children, icon, footer, className = "", quote } = props;

  return (
    <div className={`OpinionTile p-lg-5 p-md-4 p-3 pt-4 ${footer ? "" : "OpinionTile--no-footer"} ${className}`}>
      {quote}
      {icon ? <img src={icon} alt="User" className="icon my-md-4 my-3" /> : null}
      {children}
      {footer ? <div className="tile-footer">{footer}</div> : null}
    </div>
  );
};

export default OpinionTile;
