import React from "react";
import { useLang } from "../../../../features/lang-switch/useLang";
import useWindowSize from "../../../../features/useWindowSize";
import Button from "../../../../libs/buttons/Button/Button";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Grid from "../../../../libs/grid/grid";
import Row from "../../../../libs/grid/row";
import "./HeroSection.scss";
import PanelImg from "./panel.png";

interface HeroSectionProps { }

const HeroSection: React.FC<HeroSectionProps> = (props) => {
  const { T } = useLang();
  const { isMobile } = useWindowSize();

  return (
    <section className="HeroSection">
      <Container>
        <Grid>
          <Row gap={8} align="center">
            <Col size={[5, 5, 5, 12]} className="content">
              <div>
                <div className="mt-lg-1 label">IRM</div>
                <h1>
                  {T(
                    <>
                      Uwolnij potencjał
                      <br /> społeczności
                    </>,
                    <>Unleash the potential <br /> of the community</>
                  )}
                </h1>
                <p>{T(<>Technologia do pozyskiwania kapitału {isMobile ? <br /> : null}i automatyzacji pracy</>, <>Technology for raising capital and work automation</>)}</p>
              </div>
              <div className="cta-buttons d-flex mb-3 center-y">
                <a href="mailto:contact@irmatiq.com" target="_blank" rel="noreferrer">
                  <Button size="regular">{T("Porozmawiajmy", "Let's talk")}</Button>
                </a>
              </div>
            </Col>
            <Col size={[7, 7, 7, 12]} className="hero-img">
              <img src={PanelImg} alt="Irmatiq" />
            </Col>
          </Row>
          <Row className="py-3">
            <Col size={12} className="users-list">
              <p className="pre-userlist-label">{T("Pomagamy w pracy z:", "We help you work with")}</p>
              {T(<>
                {["Inwestorzy", "Fundraising", "Akcjonariusze", "Pożyczkodawcy", "Projekty deweloperskie", "Darczyńcy osoby/instytucje"].map((x, index) => (
                  <Button variant="secondary" size="small" key={index}>
                    {x}
                  </Button>
                ))}
              </>,
                <>
                  {["Investors", "Fundraising", "Shareholders", "Lenders", "Real Estate", "Donors/NGOs"].map((x, index) => (
                    <Button variant="secondary" size="small" key={index}>
                      {x}
                    </Button>
                  ))}
                </>
              )}

            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  );
};

export default HeroSection;
