import React, { useContext, useEffect, useRef, useState } from "react";
import useEffectAfterMounted from "../../../features/useEffectAfterMounted";
import { FormContext } from "../Form/FormContext";
import "./TextInput.scss";

interface TextInputProps {
  id: string;
  label?: string | React.ReactNode;
  name?: string;
  type?: 'text' | 'email'
  validation_msg?: string;
  required?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
  className?: string;
}

const TextInput: React.FC<TextInputProps> = (props) => {
  const { id, label, className, validation_msg, required=false, onChange, type = "text" } = props;
  const [value, setValue] = useState('');
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<any>();
  const { resetIndex } = useContext(FormContext);

  useEffectAfterMounted(() => {
    setValue('');
    setFocus(false);
  }, [resetIndex])

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div className={`form-child TextInput ${className} ${value ? 'isDirty' : ''} ${focus ? 'isFocus' : ''}`}>
      <label htmlFor={id}>
        <span className="label">{label}  {(required ? '*' : '')}</span> <span className={`validation-messages ${validation_msg ? "active" : ""}`}>{validation_msg ? <>({validation_msg})</> : <>&nbsp;</>}</span>
      </label>
      <input ref={inputRef} type={type} required={required} id={id} onChange={(e) => setValue(e.target.value)} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} />
    </div >
  );
};

export default TextInput;
