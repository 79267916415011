import React from "react";
import "./rules-page.scss";
import ContactSection from "../home-page/sections/ContactSection/ContactSection";
import TextSection from "./sections/TextSection/TextSection";
import HelmetSection from "./sections/HelmetSection/HelmetSection";

const RulesPage: React.FC = () => {

  return (
    <>
      <HelmetSection />
      <TextSection />
      <ContactSection />
    </>
  );
};

export default RulesPage;
