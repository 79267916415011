export const handleScrollToSection = (sectionId: string, navOffset?: number, isSmooth?: boolean) => {
  var section = document.getElementById(sectionId);
  if (section) {
    if (isSmooth) {
      let options = {
        left: 0,
        top: section!.getBoundingClientRect().top + window.pageYOffset - (navOffset || navOffset === 0 ? navOffset : 80),
        behavior: "smooth",
      } as ScrollToOptions;
      window.scrollTo(options);
    } else {
      window.scrollTo(0, section!.getBoundingClientRect().top + window.pageYOffset - (navOffset || navOffset === 0 ? navOffset : 80));
    }
  }
};
