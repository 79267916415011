import React from "react";
import "./text-block.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const TextBlock: React.FC<Props> = (props: Props) => {
  const { children, className } = props;

  return (
    <div className={`text-block${className ? " " + className : ""}`}>
      {children}
    </div>
  );
};

export default TextBlock;
