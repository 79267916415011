import React, { useEffect } from "react";
import Col from "../../libs/grid/col";
import Container from "../../libs/grid/container";
import Row from "../../libs/grid/row";
import "./navbar.scss";
// import { showSlideOverlay } from "../router-transition/routerTransitionSlice";
// import { useAppDispatch } from "../../redux/store";
import LogoImg from "./../../assets/logo.svg";
import TopLink from "../top-link/top-link";
import { RoutePaths } from "../../route-paths";
import Subnavbar from "./subnavbar";
import LangSwitch from "../../features/lang-switch/lang-switch";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import useWindowSize from "../../features/useWindowSize";
import { selectRouterTransition_is_navbarOpenActive, setNavbarOpen } from "../router-transition/routerTransitionSlice";
import MenuBtn from "./menu-btn/menu-btn";
import CallBtn from "./CallBtn/CallBtn";
import { useLang } from "../../features/lang-switch/useLang";
import EuLogosImg from "./../../assets/eu_logos_rev.svg";

interface Props {}

const Navbar: React.FC<Props> = (props: Props) => {
  var dispatch = useAppDispatch();
  const size = useWindowSize();
  const is_navbarOpenActive = useAppSelector(selectRouterTransition_is_navbarOpenActive);
  const { lang } = useLang();

  useEffect(() => {
    if (!size.isMobile) {
      dispatch(setNavbarOpen(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size.isMobile]);

  useEffect(() => {
    createObserver();
  }, []);

  const createObserver = () => {
    const el = document.querySelector(".navbar");
    const main = document.querySelector(".main");

    const observer = new IntersectionObserver(
      ([e]) => {
        // e.target.classList.toggle("stuck", e.intersectionRatio < 1);
        if (main) {
          main.classList.toggle("navbar-stuck", e.intersectionRatio < 1);
        }
      },
      { threshold: [1] }
    );
    if (el) {
      observer.observe(el);
    }
  };

  const toggleableNav = () => {
    return (
      <>
        <Subnavbar />
        <LangSwitch className="nav-item" />
        <CallBtn className="nav-item" size="small" />
        {/* <div className="nav-item nav-item-eu-logos hideOnMobile">
          <img src={EuLogosImg} alt="EU" />
        </div> */}
      </>
    );
  };

  return (
    <nav className="navbar">
      <Container className="not-mobile-container">
        <Row gap={0} align="center">
          <Col size={[5, 5, 5, 6]}>
            <div className="logos">
              <TopLink to={RoutePaths.HOME.url[lang]}>
                <img className="logo" src={LogoImg} alt="Logo" />
              </TopLink>
              <img className="eu-logo-img hideOnMobile" src={EuLogosImg} alt="EU" />
            </div>
          </Col>
          <Col size={[7, 7, 7, 6]}>
            <div className="d-flex right-x center-y">{size.isMobile ? <MenuBtn isOpen={is_navbarOpenActive} onClick={() => dispatch(setNavbarOpen(!is_navbarOpenActive))} /> : <div className="d-flex center-y">{toggleableNav()}</div>}</div>
          </Col>
        </Row>
      </Container>
      <div className={`mobile-nav ${is_navbarOpenActive ? " --open" : ""}`}>{toggleableNav()}</div>
    </nav>
  );
};

export default Navbar;
