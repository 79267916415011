import React from "react";
import "./CallToPricingSection.scss";
import { useLang } from "../../../features/lang-switch/useLang";
import { NavLink } from "react-router-dom";
import { handleScrollToSection } from "../../../features/handleScrollToSection";
import Button from "../../../libs/buttons/Button/Button";
import { RoutePaths } from "../../../route-paths";

interface CallToPricingSectionProps {
  theSamePage?: boolean;
}

const CallToPricingSection: React.FC<CallToPricingSectionProps> = (props) => {
  const { T, lang } = useLang();
  const { theSamePage } = props;

  return (
    <section className="CallToPricingSection">
      {theSamePage ? (
        <Button onClick={() => handleScrollToSection("pricing")}>{T("SPRAWDŹ CENNIK", "CHECK THE PRICING")}</Button>
      ) : (
        <NavLink to={RoutePaths.HOME.url[lang] + "#pricing"}>
          <Button>{T("SPRAWDŹ CENNIK", "CHECK THE PRICING")}</Button>
        </NavLink>
      )}
    </section>
  );
};

export default CallToPricingSection;
