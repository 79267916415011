import React, { useEffect, useState } from "react";
import "./router-transition.scss";
import { useNavigate } from "react-router-dom";
import { selectRouterTransition, hideSlideOverlay, setNavbarOpen } from "./routerTransitionSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";

const RouterTransition: React.FC = () => {
  const [overlay, setOverlay] = useState(false);
  const [opacity, setOpacity] = useState(false);
  const navigate = useNavigate();
  const routerTransition = useAppSelector(selectRouterTransition);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (routerTransition.slideOverlay.isActive === true) {
      show(routerTransition.slideOverlay.redirectTo);
    } else {
      hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerTransition]);

  const show = (redirectTo: string) => {
    setOverlay(true);
    setOpacity(true);
    window.scrollTo(0, 0);
    dispatch(setNavbarOpen(false));
    navigate(redirectTo as string);
    setTimeout(() => {
      dispatch(hideSlideOverlay());
    }, 200);
  };

  const hide = () => {
    setOpacity(false);
    setTimeout(() => {
      setOverlay(false);
    }, 200);
  };

  return (
    <div className={`router-transition ${overlay ? "active" : ""}`}>
      <div className={`router-transition__opacity ${opacity ? "active" : ""}`} />
    </div>
  );
};

export default RouterTransition;
