import React from "react";
import './NumberTile.scss';

interface NumberTileProps {
  children: React.ReactNode;
  num: string | number;
}

const NumberTile: React.FC<NumberTileProps> = (props) => {
  const { children, num } = props;

  return (
    <div className="NumberTile">
      <div className="gradient-circle">
        {num}
      </div>
      <div className="content mt-4 mt-xl-5 px-3 px-xl-4">
        {children}
      </div>
    </div>
  );
};

export default NumberTile;
