import React from "react";
import "./home-page.scss";
import ContactSection from "./sections/ContactSection/ContactSection";
import HeroSection from "./sections/HeroSection/HeroSection";
import HelmetSection from "./sections/HelmetSection/HelmetSection";
import VisualizationsSection from "./sections/VisualizationsSection/VisualizationsSection";
import PartnersSection from "./sections/PartnersSection/PartnersSection";
import IrmatiqUsage from "./sections/IrmatiqUsage/IrmatiqUsage";
import IrmatiqUsers from "./sections/IrmatiqUsage/IrmatiqUsers";
import BeforeAfterSection from "./sections/BeforeAfterSection/BeforeAfterSection";
import OpinionsSection from "./sections/OpinionsSection/OpinionsSection";
import IrmatiqBenefits from "./sections/IrmatiqUsage/IrmatiqBenefits";
import TakeFlowSection from "./sections/TakeFlowSection/TakeFlowSection";

const HomePage: React.FC = () => {
  return (
    <>
      <HelmetSection />
      <HeroSection />
      <PartnersSection />
      {/* <LargeMidTextSection /> */}
      {/* <NewsletterSection /> */}
      <IrmatiqUsage />
      <IrmatiqUsers />
      {/* <HowItWorks /> */}
      {/* <StandardsSection /> */}
      <TakeFlowSection />
      <IrmatiqBenefits />
      <VisualizationsSection />
      <BeforeAfterSection />
      {/* <InfoSection /> */}
      {/* <PricingSection /> */}
      <OpinionsSection />
      <ContactSection />
      {/* <CtaSection /> */}
    </>
  );
};

export default HomePage;
