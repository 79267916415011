import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import "./PartnersSection.scss";

import PartnerAImg from "./logo_arkleybrincvc_black_program.svg";
import PartnerBImg from "./kombinat.jpeg";
import PartnerCImg from "./reakto.png";
import PartnerDImg from "./doctor_brew.png";

interface PartnersSectionProps {}

const PartnersSection: React.FC<PartnersSectionProps> = (props) => {
  return (
    <section className="PartnersSection py-3">
      <Container>
        <Row>
          <Col size={[12, 12, 12, 12]} className="logos-wrapper">
            <div className="partner-logo" style={{ backgroundImage: `url(${PartnerBImg})` }} />
            <div className="partner-logo" style={{ backgroundImage: `url(${PartnerDImg})` }} />
            <div className="partner-logo" style={{ backgroundImage: `url(${PartnerCImg})` }} />
            <div className="partner-logo" style={{ backgroundImage: `url(${PartnerAImg})` }} />
            <div className="partner-logo" style={{ backgroundImage: `url("/images/logos/1.jpeg")` }} />
            <div className="partner-logo" style={{ backgroundImage: `url("/images/logos/3.jpeg")` }} />
            <div className="partner-logo" style={{ backgroundImage: `url("/images/logos/4.jpeg")` }} />
            <div className="partner-logo" style={{ backgroundImage: `url("/images/logos/2.jpeg")` }} />

          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PartnersSection;
