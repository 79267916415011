import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import { useLang } from "../../../../features/lang-switch/useLang";
import "./BeforeAfterSection.scss";
import RainbowTile from "../IrmatiqUsage/components/Tile/RainbowTile";
import ArrowRightImg from "./arrow-right.png";

interface BeforeAfterSectionProps { }

const BeforeAfterSection: React.FC<BeforeAfterSectionProps> = (props) => {
  const { T } = useLang();

  return (
    <section className="BeforeAfterSection py-3">
      <Container className="py-5">
        <Row className="align-center">
          <Col size={[5, 5, 6, 12]} className="before-irmatiq py-3">
            {T(
              <RainbowTile isSad>
                <h2>Przed Irmatiq</h2>
                <ul>
                  <li>❌ Wrażliwe dane w różnych arkuszach</li>
                  <li>❌ Dokumentacja w kilku folderach na komputerze i segregatorach biurowych</li>
                  <li>❌ Komunikacja przez różne e-maile i niedostosowane narzędzia od marketingu</li>
                  <li>❌ Ręczne sprawdzanie i wysyłka tysięcy przelewów przy każdym okresie odsetkowym</li>
                  <li>❌ Brak statusów w komunikacji (część użytkowników nie otrzymała odpowiedzi lub środków na czas)</li>
                  <li>❌ Dużo powtarzalnej, czasochłonnej pracy, również po godzinach</li>
                  <li>❌ Mało czasu na budowanie jakościowych relacji, czyli na tym co najważniejsze</li>
                </ul>
              </RainbowTile>,
              <RainbowTile isSad>
                <h2>Before Irmatiq</h2>
                <ul>
                  <li>❌ Sensitive data in different sheets</li>
                  <li>❌ Documentation in several folders on computer and office binders</li>
                  <li>❌ Communication through various emails and unsuited marketing tools</li>
                  <li>❌ Manual processing of thousands of transfers each interest period</li>
                  <li>❌ Lack of statuses in communication (some users did not receive a reply or funds on time)</li>
                  <li>❌ Lots of repetitive, time-consuming work, also after hours</li>
                  <li>❌ Little time to build quality relationships, which is what matters most</li>
                </ul>
              </RainbowTile>
            )}
          </Col>
          <Col size={[1, 1, 0, 0]} className="text-center hide-on-mobile">
            <img alt="right" src={ArrowRightImg} width={64} />
          </Col>
          <Col size={[6, 6, 6, 12]} offset={[0, 0, 0, 0]} className="after-irmatiq py-3">
            {T(
              <RainbowTile isRainbow>
                <h2>Z Irmatiq</h2>
                <ul>
                  <li>✔️ Wszystkie dane interesariuszy w jednym bezpiecznym miejscu</li>
                  <li>✔️ Dokumenty przypisane do inwestorów czy rund finansowania</li>
                  <li>✔️ Cyfryzacja procesu</li>
                  <li>✔️ Mniej czasu poświęcone na powtarzalne procesy i administracje, więcej jakościowej pracy nad relacjami</li>
                  <li>✔️ Kontrola nad organizacją emisji czy zbiórki</li>
                  <li>✔️ Narzędzie zaprojektowane z myślą o relacjach, aby skupić się na tym, co naprawdę ważne</li>
                  <li>✔️ Profesjonalny wizerunek</li>
                </ul>
              </RainbowTile>,
              <RainbowTile isRainbow>
                <h2>With Irmatiq</h2>
                <ul>
                  <li>✔️ All stakeholder data in one secure location</li>
                  <li>✔️ Documents assigned to investors or funding rounds</li>
                  <li>✔️ Digitisation of the process</li>
                  <li>✔️ Less time spent on repetitive processes and administration, more quality work on relationships</li>
                  <li>✔️ Control over the organisation of issuance or fundraising </li>
                  <li>✔️ A tool specifically designed for relationships, to focus on what really matters</li>
                  <li>✔️ Professional image</li>
                </ul>
              </RainbowTile>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BeforeAfterSection;
