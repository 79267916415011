import React, { useContext, useEffect, useRef, useState } from "react";
import useEffectAfterMounted from "../../../features/useEffectAfterMounted";
import { FormContext } from "../Form/FormContext";
import "./CheckBox.scss";

interface CheckBoxProps {
  id: string;
  label?: React.ReactNode;
  name?: string;
  validation_msg?: string;
  required?: boolean;
  placeholder?: string;
  onChange: (value: boolean) => void;
  className?: string;
}

const CheckBox: React.FC<CheckBoxProps> = (props) => {
  const { id, label, className, validation_msg, required, onChange } = props;
  const [value, setValue] = useState<boolean>(false);
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<any>();
  const { resetIndex } = useContext(FormContext);

  useEffectAfterMounted(() => {
    setValue(false);
    setFocus(false);
  }, [resetIndex])

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div className={`form-child CheckBox ${className} ${value ? 'isDirty' : ''} ${focus ? 'isFocus' : ''}`}>
      <span className={`validation-messages ${validation_msg ? "active" : ""}`}>{validation_msg ? <>({validation_msg})</> : <>&nbsp;</>}</span>
      <label htmlFor={id}>
        <input ref={inputRef} type="checkbox" required={required} id={id} onChange={(e) => setValue(e.target.checked as any)} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} />
        <span className="label">{label}{required ? '*' : ''}</span>
      </label>
    </div >
  );
};

export default CheckBox;
