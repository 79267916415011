import React, { useEffect } from "react";
import "./PricingSection.scss";
import MarkIcon from "../../../components/MarkIcon/MarkIcon";
import { useLang } from "../../../features/lang-switch/useLang";
import Col from "../../../libs/grid/col";
import Container from "../../../libs/grid/container";
import Row from "../../../libs/grid/row";
import TextBlock from "../../../libs/text-block/text-block";
import Tile from "../../home-page/sections/HowItWorks/components/Tile/Tile";
import CallBtn from "../../../components/navbar/CallBtn/CallBtn";
import { useLocation } from "react-router-dom";
import { handleScrollToSection } from "../../../features/handleScrollToSection";
// import EnRegImg from "./en_reg.svg";
// import PlRegImg from "./pl_reg.svg";
import EnIndImg from "./en_ind.svg";
import PlIndImg from "./pl_ind.svg";

interface PricingSectionProps {}

const PricingSection: React.FC<PricingSectionProps> = (props) => {
  const { T } = useLang();
  const location = useLocation();
  const scrollToSection = () => {
    if (location.hash) {
      handleScrollToSection(location.hash.replace("#", ""));
    }
  };

  useEffect(() => {
    scrollToSection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="PricingSection py-5" id="pricing">
      <Container className="pb-4">
        <Row>
          <Col size={[12, 12, 12, 12]}>
            <TextBlock>
              <h2 className="py-5">{T("Cennik", "Pricing")}</h2>
            </TextBlock>
          </Col>
          <Col size={[4, 4, 12, 12]} className="align-center-col">
            <TextBlock>
              <h3 className="py-3 pr-0 pr-xl-4">{T(<>Poznaj nową jakość prowadzenia relacji inwestorskich w spółkach prywatnych</>, <>Experience the new quality of investor relations in private companies</>)}</h3>
              <p className="py-3 py-xl-3">
                {T(
                  <>
                    <b>funkcjonalności:</b> <br />
                    <MarkIcon />
                    &nbsp; IRM/CRM <br />
                    <MarkIcon />
                    &nbsp; Obieg dokumentów <br />
                    <MarkIcon />
                    &nbsp; Konto inwestora <br />
                    <MarkIcon />
                    &nbsp; Moduł komunikacji <br />
                    <MarkIcon />
                    &nbsp; Moduł rund finansowania <br />
                  </>,
                  <>
                    <b>features:</b> <br />
                    <MarkIcon />
                    &nbsp; IRM/CRM <br />
                    <MarkIcon />
                    &nbsp; Document management system <br />
                    <MarkIcon />
                    &nbsp; Investor account
                    <br />
                    <MarkIcon />
                    &nbsp; Communication feature <br />
                    <MarkIcon />
                    &nbsp; Financing rounds feature
                    <br />
                  </>
                )}
              </p>
            </TextBlock>
          </Col>

          <Col size={[4, 4, 6, 12]} className="py-3">
            <Tile>
              <div className="pricing-label">{T("Pakiet Podstawowy", "Basic Option")}</div>
              <div className="pricing-price py-3 my-3">
                {T(<img src={PlIndImg} alt="CENNIK INDYWIDUALNY" />, <img src={EnIndImg} alt="CUSTOM PRICING" />)}
              </div>
              <div className="options-wrapper mb-5">
                {T(
                  <ul>
                    <li>wszystkie funkcje Irmatiq</li>
                    <li>do 100 inwestorów w spółce</li>
                  </ul>,
                  <ul>
                    <li>all Irmatiq functions</li>
                    <li>up to 100 investors in your company</li>
                  </ul>
                )}
              </div>
              <CallBtn className="mt-auto">{T("UMÓW SIĘ NA DEMO", "BOOK A DEMO")}</CallBtn>
              <div className="pb-5"></div>
            </Tile>
          </Col>

          <Col size={[4, 4, 6, 12]} className="py-3">
            <Tile>
              <div className="pricing-label">{T("Pakiet Zaawansowany", "Advanced Option")}</div>
              <div className="pricing-price py-3 my-3">{T(<img src={PlIndImg} alt="CENNIK INDYWIDUALNY" />, <img src={EnIndImg} alt="CUSTOM PRINCING" />)}</div>
              <div className="options-wrapper mb-5">
                {T(
                  <ul>
                    <li>cena ustalana indywidualnie na podstawie liczby inwestorów</li>
                    <li>funkcje do zarządzania grupami kapitałowy</li>
                  </ul>,
                  <ul>
                    <li>custom pricing based on the number of investors</li>
                    <li>capital groups functionalities</li>
                  </ul>
                )}
              </div>
              <CallBtn className="mt-auto">{T("UMÓW SIĘ NA DEMO", "BOOK A DEMO")}</CallBtn>
              <div className="pb-5"></div>
            </Tile>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PricingSection;
