import React from "react";
import { useLocation } from "react-router-dom";
import { useLang } from "../../features/lang-switch/useLang";
import { RoutePaths } from "../../route-paths";

interface Props {
  children: React.ReactNode;
}

const RouterController: React.FC<Props> = (props: Props) => {

  const location = useLocation();
  const { lang } = useLang();

  const tryToFindRouteName = (url: string): string => {
    var routeName = 'url--' + url;

    for (let key in RoutePaths) {
      var route = (RoutePaths as any)[key];
      if (route) {
        if (route.url === url || (route.url[lang] && route.url[lang] === location.pathname)) {
          routeName = route.name;
        }
      }
    }
    return routeName;
  }

  return (
    <div className={tryToFindRouteName(location.pathname)}>
      {props.children}
    </div>
  );
};

export default RouterController;