import React, { useState } from "react";
import "./Form.scss";
import { FormContext } from "./FormContext";

interface FormProps {
  id: string;
  children: React.ReactNode;
  onSubmit?: any;
  className?: string;
}

const Form: React.FC<FormProps> = (props) => {
  const { id, children, onSubmit, className } = props;
  const [resetIndex, setResetIndex] = useState(0)

  const setClassName = (): string => {
    return className ? " " + className : "";
  };

  const handleSubmit = (e: any) => {
    var data = {} as any;
    for (let index = 0; index < e.target.elements.length; index++) {
      const element = e.target.elements[index];
      if (element.nodeName === 'INPUT') {
        if (element.type === 'checkbox') {
          data[element.id] = element.checked;
        } else {
          data[element.id] = element.value;
        }
      }
    }
    e.preventDefault();
    onSubmit(data);
  }

  return (
    <FormContext.Provider value={{ resetIndex: resetIndex }}>
      <form
        id={id}
        className={`Form ${setClassName()}`}
        onSubmit={handleSubmit}
        onReset={() => setResetIndex((state) => state + 1)}
      >
        {children}
      </form>
    </FormContext.Provider>
  );
};

export default Form;
