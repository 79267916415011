import React from "react";
import Col from "../../libs/grid/col";
import Row from "../../libs/grid/row";
import './ImgInfoRow.scss';

interface ImgInfoRowProps {
  children: React.ReactNode;
  icon?: string;
  iconAlt?: string;
  img: string;
  imgAlt?: string;
  reverse?: boolean;
  className?: string;
  noShadow?: boolean;
  size?: 'small' | 'large';
}

const ImgInfoRow: React.FC<ImgInfoRowProps> = (props) => {
  const { children, img, imgAlt = "", reverse, className, noShadow, size = "large" } = props;

  return (
    <Row>
      <Col size={size === 'large' ? 12 : [10, 10, 12]} offset={size === 'large' ? 0 : [1, 1, 0]}>
        <Row align="center" className={`ImgInfoRow ImgInfoRow--${size} py-2 py-xl-4 my-2 my-xl-4 ${reverse ? ' reversed' : ''}${noShadow ? ' ImgInfoRow--no-shadow' : ''}${className ? className : ''}`}>
          <Col size={[6, 6, 6, 12]} offset={reverse ? [1, 1, 0] : [0]} className="content-col">
            <div className="px-xl-5">
              {children}
            </div>
          </Col>
          <Col size={[5, 5, 6, 12]} offset={reverse ? [0] : [1, 1, 0]} className={`img-col ${reverse ? '' : 'pr-xl-5'}  pt-3 pt-xl-0`}>
            <div className="main-img-wrapper">
              <div className="main-img" style={{ backgroundImage: `url(${img})` }} title={imgAlt} />
            </div>
          </Col>
        </Row>
      </Col>
    </Row >
  );
};

export default ImgInfoRow;
