// export class RoutePath {
//   [key: string]: any;
//   static RULES: string = '/warunki-uzytkowania';
//   static ABOUT: string = '/about';
//   static HOME: string = '/';
// }
// import ArticlesNames from  `${process.env.PUBLIC_URL}/manifest.json`; //"./data/blog-data/articles.json";
// var ArticlesNames = require(`${process.env.PUBLIC_URL}/data/blog-data/articles.json`);
// (window as any).allArticles = [];
// for (let i = 0; i < ArticlesNames.length; i++) {
//   let article = require(`${process.env.PUBLIC_URL}/data/blog-data/articles/${ArticlesNames[i]}/index.json`);
//   (window as any).allArticles.push({
//     name: ArticlesNames[i],
//     ...article,
//   });
// }

// var listOfArticles = () => {
//   var rv: any = {};
//   for (var i = 0; i < (window as any).allArticles.length; ++i) {
//     let article = (window as any).allArticles[i];
//     if (article) {
//       rv[article.name] = {
//         ...article.route,
//         name: article.name
//       }
//     }
//   }
//   return rv;
// }

export const RoutePaths = {
  // ...listOfArticles(),
  RULES: {
    name: "rules-page",
    url: {
      pl: "/pl/polityka-prywatnosci",
      en: "/en/privacy-policy",
    },
  },
  INDUSTRIES: {
    name: "industries-page",
    url: {
      pl: "/pl/branze",
      en: "/en/industries",
    },
  },
  SOLUTIONS: {
    name: "solutions-page",
    url: {
      pl: "/pl/rozwiazania",
      en: "/en/solutions",
    },
  },
  ABOUT: {
    name: "about-page",
    url: {
      pl: "/pl/o-nas",
      en: "/en/about",
    },
  },
  ARTICLELOADER: {
    name: "article-loader-page",
    url: {
      pl: "/pl/zasoby/:id",
      en: "/en/resources/:id",
    },
  },
  ARTICLE: {
    name: "article-page",
    url: {
      pl: "/pl/zasoby/:id/:name",
      en: "/en/resources/:id/:name",
    },
  },
  BLOG: {
    name: "blog-page",
    url: {
      pl: "/pl/zasoby",
      en: "/en/resources",
    },
  },
  EMPTY_BLOGTAG: {
    name: "empty-tag-page",
    url: {
      pl: "/pl/zasoby/tag",
      en: "/en/resources/tag",
    },
  },
  BLOGTAG: {
    name: "tag-page",
    url: {
      pl: "/pl/zasoby/tag/:tag",
      en: "/en/resources/tag/:tag",
    },
  },
  HOME: {
    name: "home-page",
    url: {
      pl: "/",
      en: "/",
    },
  },
  HOME_lang: {
    name: "home-page",
    url: {
      pl: "/pl/",
      en: "/en/",
    },
  },
};
