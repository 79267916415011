import React from "react";
import "./solutions-page.scss";
import InfoSection from "./sections/InfoSection/InfoSection";
import LargeMidTextSectionWithBg from "./sections/LargeMidTextSectionWithBg/LargeMidTextSectionWithBg";
import TilesSection from "./sections/TilesSection/TilesSection";
import InfoSectionPrimary from "./sections/InfoSectionPrimary/InfoSectionPrimary";
import LargeMidTextSectionWithBgBottom from "./sections/LargeMidTextSectionWithBgBottom/LargeMidTextSectionWithBgBottom";
import HelmetSection from "./sections/HelmetSection/HelmetSection";
import PricingSection from "../shared/PricingSection/PricingSection";

const SolutionsPage: React.FC = () => {
  return (
    <>
      <HelmetSection />
      <div className="solutions-page">
        <LargeMidTextSectionWithBg />
        <TilesSection />
        <InfoSection />
        <InfoSectionPrimary />
        <PricingSection />
        <LargeMidTextSectionWithBgBottom />
      </div>
    </>
  );
};

export default SolutionsPage;
