import React from "react";
import TopLink from "../top-link/top-link";
import { RoutePaths } from "../../route-paths";
import { useLang } from "../../features/lang-switch/useLang";

interface Props {
  className?: string;
  hideUnderline?: boolean;
}
const Subnavbar: React.FC<Props> = (props: Props) => {
  const { className = "", hideUnderline } = props;
  const { T, lang } = useLang();

  return (
    <div className={`subnavbar ${className} ${hideUnderline ? "hiddenUnderline" : ""}`}>
      <TopLink to={RoutePaths.SOLUTIONS.url[lang]}>{T("Produkt", "Product")}</TopLink>
      <TopLink to={RoutePaths.INDUSTRIES.url[lang]}>{T("Dla kogo?", "Clients")}</TopLink>
      <TopLink to={RoutePaths.BLOG.url[lang]}>Blog</TopLink>
      <TopLink to={RoutePaths.ABOUT.url[lang]}>{T("O nas", "About Us")}</TopLink>
    </div>
  );
};

export default Subnavbar;
