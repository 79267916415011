import React from "react";
import { useLang } from "../../../../features/lang-switch/useLang";
import Col from "../../../../libs/grid/col";
import Row from "../../../../libs/grid/row";
import Container from "../../../../libs/grid/container";
import NumberTile from "../../../../components/NumberTile/NumberTile";
import CallBtn from "../../../../components/navbar/CallBtn/CallBtn";
import "./TilesSection.scss";

interface TilesSectionProps {}

const TilesSection: React.FC<TilesSectionProps> = () => {
  const { T } = useLang();

  return (
    <section className="TilesSection py-4 my-4">
      <Container>
        <Row>
          <Col size={[3, 3, 3, 6]} className="py-4">
            <NumberTile num={1}>{T(<>Posiadasz przynajmniej kilku inwestorów, z którymi starasz się utrzymywać kontakt? Informujesz ich o emisji długu, akcji lub udziałów?</>, <>Do you have at least 3 investors that you need to keep in touch with? Do you need to keep them updated about your debt, stock, or equity issues?</>)}</NumberTile>
          </Col>
          <Col size={[3, 3, 3, 6]} className="py-4">
            <NumberTile num={2}>{T(<>Szukasz rozwiązania, dzięki któremu będziesz mógł sprawniej kontrolować obieg dokumentacji kluczowej dla inwestorów?</>, <>Are you looking for an effective solution to control the circulation of important documents?</>)}</NumberTile>
          </Col>
          <Col size={[3, 3, 3, 6]} className="py-4">
            <NumberTile num={3}>{T(<>To wszystko w jednym narzędziu: intuicyjny IRM/CRM, generowanie oraz zarządzanie niezbędnymi dokumentami, Konto Inwestora i wiele więcej</>, <>You can have it all in just one tool: an intuitive IRM/CRM, document storage and management, investor accounts, and much more</>)}</NumberTile>
          </Col>
          <Col size={[3, 3, 3, 6]} className="py-4">
            <NumberTile num={4}>{T(<> Zaoszczędź czas na powtarzalnych procesach dzięki prostej automatyzacji i ciesz się szybszym procesem obsługi inwestycji. Z Irmatiq już nigdy nie zgubisz się w arkuszach, mailach czy folderach</>, <>Save time on repetitive processes with easy-to-apply automation. With Irmatiq, you will never get lost in emails, folders, or sheets again</>)}</NumberTile>
          </Col>
        </Row>
        <Row>
          <Col size={12} className="d-flex">
            <CallBtn size="large" className="mx-auto" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TilesSection;
