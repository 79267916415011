import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { set } from "../../features/lang-switch/langSlice";
import { useLang } from "../../features/lang-switch/useLang";
import { useAppDispatch } from "../../redux/store";

interface ChangeLangProps {}

const ChangeLang: React.FC<ChangeLangProps> = (props) => {
  const { lang } = useLang();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const setLanguage = () => {
    if (location.pathname.indexOf("/en/") !== -1 && lang === "pl") {
      dispatch(set("en"));
    } else if (location.pathname.indexOf("/pl/") !== -1 && lang === "en") {
      dispatch(set("pl"));
    }
  };

  useEffect(() => {
    setLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   setLanguage();
  // }, [location]);

  return <></>;
};

export default ChangeLang;
