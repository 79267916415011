import React from "react";
import MarkIcon from "../../../../components/MarkIcon/MarkIcon";
import TopLink from "../../../../components/top-link/top-link";
import { useLang } from "../../../../features/lang-switch/useLang";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import { RoutePaths } from "../../../../route-paths";
import './InfoSectionGrid.scss';
import CImg from "./3.svg";

interface InfoSectionGridProps {
}

const InfoSectionGrid: React.FC<InfoSectionGridProps> = (props) => {
  const { T, lang } = useLang();

  return (
    <section className="InfoSectionGrid py-5 py-lg-5 mt-4 mt-xl-0 py-xl-5">
      <Container className="content py-xl-3">
        <div className="px-0 px-lg-3 px-xl-5 py-3 py-xl-5">
          <Row className="py-3">
            <Col size={[4, 5, 5, 12]}>
              <h2 className="pt-0 mt-0 mt-md-5 pt-md-5">
                {T(
                  <>Irmatiq w pigułce</>,
                  <>Irmatiq in a brief</>
                )}
              </h2>
              <p className="py-3">
                {T(
                  <>Kompletne narzędzie wspomagające zarządzanie inwestorami w spółkach</>,
                  <>Comprehensive tool helping investors management in private companies</>
                )}

              </p>
            </Col>
            <Col size={[6, 5, 6, 12]} offset={[1, 1, 1, 0]} className="section-hero-img-wrapper">
              <img src={CImg} alt="Irmatiq" />
            </Col>
          </Row>
          <Row className="py-3 mt-xl-3">
            <Col size={[5, 6, 6, 12]}>
              <div className="description py-2 pr-3 py-lg-3 my-xl-3 pr-lg-4">
                <div className="d-flex">
                  <div className="pr-2 pb-2 col-info-wrapper">
                    <MarkIcon />
                  </div>
                  <b>
                    {T(
                      <>Dla kogo jest Irmatiq?</>,
                      <>Who is Irmatiq for?</>
                    )}
                  </b>
                </div>
                {T(
                  <>Irmatiq to idealne rozwiązanie dla biznesów prywatnych, które mają od 3 do nawet kilku tysięcy lub więcej inwestorów. Nasze narzędzie jest cennym wsparciem dla spółek crowdfundingowych, projektów deweloperskich czy ASI</>,
                  <>Irmatiq is made for private companies that have at least 3 to even thousands of investors. Our tool provides valuable support to organizations with equity crowdfunding, property projects, or joint-stock companies.</>
                )}
              </div>
            </Col>
            <Col size={[5, 6, 6, 12]} offset={[1, 0]}>
              <div className="description py-2 pr-3 py-lg-3 my-xl-3 pr-lg-4">
                <div className="d-flex">
                  <div className="pr-2 pb-2 col-info-wrapper">
                    <MarkIcon />
                  </div>
                  <b>
                    {T(
                      <>Co zyskasz z naszym narzędziem?</>,
                      <>What will you gain with our tool?</>
                    )}
                  </b>
                </div>
                {T(
                  <>Z naszym narzędziem zautomatyzujesz powtarzalne procesy i zaoszczędzisz czas. Pomagamy uprościć zarządzanie inwestorami. Dzięki temu zbudujesz z nimi lepszą relację i pozyskasz od nich więcej środków na dalszy rozwój biznesu</>,
                  <>Our tool allows you to save time by automating repetitive processes. We help you simplify investor management. With Irmatiq, you will build better relationships with your investors, so that you can raise more funds for further development</>
                )}
              </div>
            </Col>

            <Col size={[5, 6, 6, 12]}>
              <div className="description py-2 pr-3 py-lg-3 my-xl-3 pr-lg-4">
                <div className="d-flex">
                  <div className="pr-2 pb-2 col-info-wrapper">
                    <MarkIcon />
                  </div>
                  <b>
                    {T(
                      <>Jakie funkcje oferuje Irmatiq?</>,
                      <>What are the functions of this tool?</>
                    )}
                  </b>
                </div>
                {T(
                  <>Dzięki narzędziu zyskasz dostęp do intuicyjnego IRM/CRM wspomagającego zarządzanie inwestorami, razem z zakładką relacji inwestorskich, kontem inwestora i modułem komunikacji.
                    <br /><br />
                    Irmatiq oferuje rozwiązania umożliwiające kompleksowe zarządzanie dokumentami. Nasze narzędzie pozwala również na organizację webinarów, a eksperci stale rozbudowują bazę wiedzy. <TopLink to={RoutePaths.SOLUTIONS.url[lang]}>Co jeszcze oferuje Irmatiq?</TopLink></>,
                  <>With Irmatiq, you gain access to an intuitive IRM/CRM that assists investor management, along with a customizable investor relations tab, investor accounts, and a communications panel.
                    <br /><br />
                    We also provide you with a comprehensive solution to manage your documentation efficiently. Our tool allows you to organize webinars, and our experts are constantly expanding the knowledge base. What else does Irmatiq have to offer?</>
                )}
              </div>
            </Col>

            <Col size={[5, 6, 6, 12]} offset={[1, 0]}>
              <div className="description py-2 pr-3 py-lg-3 my-xl-3 pr-lg-4">
                <div className="d-flex">
                  <div className="pr-2 pb-2 col-info-wrapper">
                    <MarkIcon />
                  </div>
                  <b>
                    {T(
                      <>Zadbaj o bezpieczeństwo</>,
                      <>Choose better security</>
                    )}
                  </b>
                </div>
                {T(
                  <>Dzięki naszemu doświadczeniu w branży wiemy, jak ważne jest bezpieczeństwo danych spółki. Dlatego Irmatiq dba o ochronę danych w chmurze, a dział pomocy technicznej szybko udzieli Ci niezbędnego wsparcia</>,
                  <>Our experience in the industry has taught us how important security is to company data nowadays. That's why Irmatiq takes care of data protection in the cloud, and our helpdesk will quickly provide you with all the necessary support you need in any situation.</>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default InfoSectionGrid;