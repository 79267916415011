import React from "react";
import { useLang } from "../../../../features/lang-switch/useLang";
import Col from "../../../../libs/grid/col";
import Row from "../../../../libs/grid/row";
import Tile from "../../../home-page/sections/HowItWorks/components/Tile/Tile";
import "./TilesSection.scss";
import MarkIconLarge from "./markiconlarge.svg";
import Container from "../../../../libs/grid/container";
import CallToPricingSection from "../../../shared/CallToPricingSection/CallToPricingSection";

interface TilesSectionProps {}

const TilesSection: React.FC<TilesSectionProps> = () => {
  const { T } = useLang();

  return (
    <section className="TilesSection py-4 my-4">
      <Container>
        <Row>
          <Col size={[4, 4, 4, 6, 12]} className="py-2">
            <Tile
              icon={MarkIconLarge}
              // footer={<TopLink to={RoutePaths.HOME.url[lang]}>{T("Dowiedz się więcej", "Learn more")}</TopLink>}
            >
              <h3>{T("Dla prywatnych spółek", "For private companies")}</h3>
              <p>{T("Nasze rozwiązanie usprawni komunikację każdej spółki z inwestorami, bez względu na ich ilość", "Irmatiq improves all your private company communications with investors, no matter how many investors you have")}</p>
            </Tile>
          </Col>
          <Col size={[4, 4, 4, 6, 12]} className="py-2">
            <Tile
              icon={MarkIconLarge}
              // footer={<TopLink to={RoutePaths.HOME.url[lang]}>{T("Dowiedz się więcej", "Learn more")}</TopLink>}
            >
              <h3>{T("Pozostań w kontakcie z inwestorami", "Stay in touch with company's investors")}</h3>
              <p>{T("Prowadź uporządkowaną dokumentację w zabezpieczonej chmurze i informuj na bieżąco inwestorów o nowych emisjach", "Keep structured documentation in a protected cloud and your investors updated on new issues")}</p>
            </Tile>
          </Col>
          <Col size={[4, 4, 4, 6, 12]} className="py-2">
            <Tile
              icon={MarkIconLarge}
              // footer={<TopLink to={RoutePaths.HOME.url[lang]}>{T("Dowiedz się więcej", "Learn more")}</TopLink>}
            >
              <h3>{T("Kompleksowe rozwiązanie", "Comprehensive solution")}</h3>
              <p>{T("Uzyskaj dostęp do prostego w obsłudze IRM/CRM do komunikacji i zarządzania inwestorami oraz wartościowej bazy wiedzy. Sprawdź inne funkcje naszej usługi", "Gain access to an intuitive IRM/CRM to communication with and manage your investors. Gain access to intuitive IRM/CRM to communicate and manage investors. Explore our expert-made knowledge base and other Irmatiq features")}</p>
            </Tile>
          </Col>
        </Row>
        <Row>
          <Col size={12} className="mt-5">
            <CallToPricingSection theSamePage />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TilesSection;
