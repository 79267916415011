import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import { useLang } from "../../../../features/lang-switch/useLang";
import VisualizationA from "./visualization-1.png";
import VisualizationB from "./visualization-2.png";
import VisualizationC from "./visualization-3.png";
import "./VisualizationsSection.scss";
import useWindowSize from "../../../../features/useWindowSize";

interface VisualizationsSectionProps {}

const VisualizationsSection: React.FC<VisualizationsSectionProps> = (props) => {
  const { T } = useLang();

  const VisualizationRow = ({ title, description, image, offset = 0, reverse }: { title: string | React.ReactNode; description?: string | React.ReactNode; image: string; offset: 0 | 1 | 2 | 3; reverse?: boolean }) => {
    const { isMobile } = useWindowSize();

    const ImageCol = () => (
      <Col size={[6, 6, 10, 12]} offset={[0, 0, offset, 0]} className="pb-md-3 pb-2">
        <img src={image} alt="Irmatiq" data-aos="fade-up" />
      </Col>
    );

    const ContentCol = () => (
      <Col size={[6, 6, 10, 12]} offset={[0, 0, offset, 0]} className="text-center section-intropb-md-4 pb-3">
        <h3 data-aos="fade-up" className="px-md-5 px-3">
          {title}
        </h3>
        {description ? description : null}
      </Col>
    );

    return (
      <Row className="pt-3 pb-3" align="center">
        {isMobile ? (
          <>
            <ImageCol />
            <ContentCol />
          </>
        ) : (
          <>
            {reverse ? (
              <>
                <ContentCol />
                <ImageCol />
              </>
            ) : (
              <>
                <ImageCol />
                <ContentCol />
              </>
            )}
          </>
        )}
      </Row>
    );
  };

  return (
    <section className="VisualizationsSection py-xl-5 py-md-4 py-3">
      <Container className="py-xl-5 py-md-4 py-3">
        <Row className="pt-3 pb-3">
          <Col size={[6, 8, 10, 12]} offset={[3, 2, 1, 0]} className="section-intro">
            <h2 className="pb-xl-4 pb-3">{T(<>Zobacz jak wygląda Irmatiq</>, <>What's inside</>)}</h2>
          </Col>
        </Row>
        <VisualizationRow
          title={T("Sprawna komunikacja i raportowanie do inwestorów czy interesariuszy", "Easy way to report to your investors or stakeholders")}
          image={T(VisualizationA, VisualizationA) as string}
          offset={0}
          // description={T(<p data-aos="fade-up">Pl!</p>, <p data-aos="fade-up">En!</p>)}
        />
        <VisualizationRow
          title={T("Pełny workflow finansowania Twojego biznesu czy projektu. Akcje, pożyczki, obligacje, donacje.", "Full workflow for financing your business or project. Stocks, loans, bonds, donations.")}
          image={T(VisualizationB, VisualizationB) as string}
          offset={1}
          reverse
          // description={T(<p data-aos="fade-up">Pl!</p>, <p data-aos="fade-up">En!</p>)}
        />
        <VisualizationRow
          title={T("Obieg dokumentów i zarządzanie dokumentacją związaną z projektem czy inwestycją", "Document management flow for your project or investment")}
          image={T(VisualizationC, VisualizationC) as string}
          offset={2}
          // description={T(<p data-aos="fade-up">Pl!</p>, <p data-aos="fade-up">En!</p>)}
        />
      </Container>
    </section>
  );
};

export default VisualizationsSection;
