import React from "react";
import { useLang } from "../../../../features/lang-switch/useLang";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import './InfoSectionPrimary.scss';

interface InfoSectionPrimaryProps {
}

const InfoSectionPrimary: React.FC<InfoSectionPrimaryProps> = (props) => {
  const { T } = useLang();

  const markIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16.866" height="12.065" viewBox="0 0 16.866 12.065">
        <path id="Icon_feather-check" data-name="Icon feather-check" d="M20.038,9l-9.651,9.651L6,14.264" transform="translate(-4.586 -7.586)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </svg>
    )
  }

  return (
    <section className="InfoSectionPrimary py-5 mt-4 mt-xl-0 py-xl-5">
      <Container className="content py-xl-3">
        <div className="px-0 px-lg-3 px-xl-5 py-3 py-xl-5">
          <Row className="py-3">
            <Col size={[4, 6, 9, 12]} className="light">
              <h2>{T("Irmatiq w pigułce", "Irmatiq in brief")}</h2>
              <p className="py-3">
                {T(<>Cyfryzacja zarządzania inwestorami w spółkach to nasza specjalność</>, <>Digitizing investor management is our specialty</>)}
              </p>
            </Col>
          </Row>
          <Row className="py-3 mt-xl-3">
            <Col size={[5, 6, 6, 12]}>
              <div className="description py-2 pr-3 py-lg-3 my-xl-3 pr-lg-4">
                <div className="d-flex">
                  <div className="pr-2 pb-2 col-info-wrapper">
                    {markIcon()}
                  </div>
                  <b>{T("Wszystko czego potrzebujesz", "All that you need")}</b>
                </div>
                {T(
                  <>Porzuć niezliczone maile, setki folderów i ogromne arkusze. W Irmatiq wszystko jest zawsze pod ręką. Dodatkowo, stawiamy na bezpieczeństwo spółek, dzięki czemu Twoje dane są dobrze zabezpieczone.</>,
                  <>Forget about sending tons of emails, or keep track of a bunch of folders and sheets. With Irmatiq, everything is organized. Moreover, we focus on security too, so your documents are well-protected.</>
                )}
              </div>
            </Col>
            <Col size={[5, 6, 6, 12]} offset={[1, 0]}>
              <div className="description py-2 pr-3 py-lg-3 my-xl-3 pr-lg-4">
                <div className="d-flex">
                  <div className="pr-2 pb-2 col-info-wrapper">
                    {markIcon()}
                  </div>
                  <b>{T("Wygoda w zarządzaniu inwestorami", "Convenience in managing investors")}</b>
                </div>
                {T(
                  <>Nasze narzędzie da ci wnieść relację z inwestorami na wyższy poziom. Automatyzuj komunikację, korzystaj z wygodnego IRM/CRM i trzymaj całą dokumentacje w jednym miejscu.</>,
                  <>Our solution will help you take your business relationships with your investors to the next level. Automate communication, use an intuitive IRM/CRM, and keep all your documentation in one place.</>
                )}
              </div>
            </Col>

            <Col size={[5, 6, 6, 12]}>
              <div className="description py-2 pr-3 py-lg-3 my-xl-3 pr-lg-4">
                <div className="d-flex">
                  <div className="pr-2 pb-2 col-info-wrapper">
                    {markIcon()}
                  </div>
                  <b>{T("W jaki sposób nasze narzędzie Ci pomoże?", "How can our tool help your company?")}</b>

                </div>
                {T(
                  <>Z nami maksymalnie uprościsz proces zarządzania inwestorami, dzięki czemu zaoszczędzisz cenny czas. Pomożemy Ci zbudować dobre relacje z inwestorami, co zwiększy szanse spółki na pozyskanie od nich kolejnych środków.</>,
                  <>With us, the investor management process is completely simplified, which results in a lot of saved time.
                    Our tool helps you build good relationships with your investors, so your organization's chances of gaining additional funding also increases.</>
                )}
              </div>
            </Col>

            <Col size={[5, 6, 6, 12]} offset={[1, 0]}>
              <div className="description py-2 pr-3 py-lg-3 my-xl-3 pr-lg-4">
                <div className="d-flex">
                  <div className="pr-2 pb-2 col-info-wrapper">
                    {markIcon()}
                  </div>
                  <b>{T("Jak działa Irmatiq?", "How does Irmatiq work?")}</b>
                </div>
                {T(
                  <>Irmatiq to narzędzie SaaS (Software-as-a-Service) stworzone z myślą o spółkach prywatnych z przynajmniej 3 inwestorami. Wszystkie funkcje dostępne są z poziomu przeglądarki. Nad wygodą używania aplikacji czuwa nasz rozbudowany dział pomocy technicznej.</>,
                  <>Irmatiq is a SaaS tool developed especially for private companies that have at least 3 investors. All of our features are available through a browser. Our helpdesk takes care of any issues that you might have to make your experience with Irmatiq the best one possible.
                  </>)}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default InfoSectionPrimary;