import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import './LargeMidTextSectionWithBg.scss';
import CirclesImg from "./circles.svg";
import { useLang } from "../../../../features/lang-switch/useLang";

interface LargeMidTextSectionWithBgProps {
}

const LargeMidTextSectionWithBg: React.FC<LargeMidTextSectionWithBgProps> = (props) => {
  const { T } = useLang();

  return (
    <section className="LargeMidTextSectionWithBg my-xl-5">
      <Container>
        <div className="section-bg py-0 pt-5 py-xl-5" style={{ backgroundImage: `url(${CirclesImg})` }}>
          <Row className="py-5">
            <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]}>
              <div className="px-lg-4 px-md-2 px-0 px-xl-5">
                <p className="pb-4">{T("Poznajmy się!", "Let's meet!")}</p>
                <h1 className="h1">
                  {T(
                    <>Let's meet!</>,
                    <>
                      We support private companies in their daily work with investors
                    </>
                  )}
                </h1>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default LargeMidTextSectionWithBg;