import React from "react";
import Col from "../../libs/grid/col";
import Container from "../../libs/grid/container";
import Row from "../../libs/grid/row";
import './prefooter.scss';
import LogoImg from "./../../assets/logo.svg";
import Subnavbar from "../navbar/subnavbar";
import EuLogosImg from "../../assets/ue_logos.svg";

interface PrefooterProps {
}

const Prefooter: React.FC<PrefooterProps> = (props) => {

  return (
    <section className="prefooter pt-4 pt-xl-5">
      <Container className="pt-xl-5">
        <Row>
          <Col size={12} className="pb-3">
            <img className="logo" src={LogoImg} alt="Irmatiq" />
          </Col>
          <Col size={[6, 6, 6, 6]}>
            <p className="content pb-3">
              Irmatiq Spółka z o.o <br />
              Widok Towers, p. X <br />
              Al. Jerozolimskie 44 <br />
              00-024 Warszawa
            </p>
          </Col>
          <Col size={[1, 2, 3, 3]} offset={[4, 2, 0, 0]} className="subnav-col">
            <Subnavbar hideUnderline={true} />
          </Col>
          <Col size={[1, 2, 3, 3]} className="social-media text-right">
            <a href="https://www.facebook.com/Irmatiq">Facebook</a> <br />
            <a href="https://www.linkedin.com/company/irmatiq/">Linkedin</a> <br />
            <a href="https://www.youtube.com/channel/UCfIqaciup6j2Hirr-uwXGAQ">YouTube</a> <br />
            <a href="https://www.instagram.com/irmatiq_com/">Instagram</a> <br />
          </Col>
          <Col size={12}>
            <div className="eu-logos-wrapper pt-4">
              <img src={EuLogosImg} alt="EU" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Prefooter;
