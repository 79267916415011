import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import { useLang } from "./features/lang-switch/useLang";
import AboutPage from "./pages/about-page/about-page";
import ArticlePage from "./pages/article-page/article-page";
import BlogPage from "./pages/blog-page/blog-page";
import IndustriesPage from "./pages/industries-page/industries-page";
import SolutionsPage from "./pages/solutions-page/solutions-page";
import HomePage from "./pages/home-page/home-page";
import Layout from "./pages/layout";
import RulesPage from "./pages/rules-page/rules-page";
import { RoutePaths } from "./route-paths";
import ChangeLang from "./components/ChangeLang/ChangeLang";
import BlogTagPage from "./pages/blog-page/blog-tag-page";
import { useEffect } from "react";
import AOS from "aos";

function App() {
  const { lang } = useLang();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      AOS.init({
        duration: 1500,
        once: true
      });
    }, 200);
  }, [location]);

  return (
    <div id="app">
      <ChangeLang />
      <Routes>
        <Route element={<Layout />}>
          <Route path={RoutePaths.HOME_lang.url[lang]} element={<HomePage />} />
          <Route path={RoutePaths.HOME.url[lang]} element={<HomePage />} />
          <Route path={RoutePaths.RULES.url[lang]} element={<RulesPage />} />
          <Route path={RoutePaths.SOLUTIONS.url[lang]} element={<SolutionsPage />} />
          <Route path={RoutePaths.INDUSTRIES.url[lang]} element={<IndustriesPage />} />
          <Route path={RoutePaths.BLOG.url[lang]} element={<BlogPage />} />
          <Route path={RoutePaths.EMPTY_BLOGTAG.url[lang]} element={<Navigate to={RoutePaths.BLOG.url[lang]} />} />
          <Route path={RoutePaths.BLOGTAG.url[lang]} element={<BlogTagPage />} />
          <Route path={RoutePaths.ABOUT.url[lang]} element={<AboutPage />} />
          <Route path={RoutePaths.ARTICLE.url.pl} element={<ArticlePage />} />
          <Route path={RoutePaths.ARTICLE.url.en} element={<ArticlePage />} />
          <Route path={RoutePaths.ARTICLELOADER.url.pl} element={<ArticlePage />} />
          <Route path={RoutePaths.ARTICLELOADER.url.en} element={<ArticlePage />} />
          <Route path={"*"} element={<HomePage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
