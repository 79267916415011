import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import './ContentSection.scss';

interface Props {
  content?: any;
  children: React.ReactNode;
}

const ContentSection: React.FC<Props> = (props) => {
  const { content, children } = props;

  return (
    <section className="ContentSection pb-4 pb-xl-5 mb-5">
      <Container>
        <Row align="center">
          <Col size={[8, 10, 12]} offset={[2, 1, 0]}>
            {content ? <div dangerouslySetInnerHTML={{ __html: content }} /> : null}
            {children ? children : null}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContentSection;