import React from "react";
import "./RainbowTile.scss";

interface TileProps {
  children: React.ReactNode;
  className?: string;
  isRainbow?: boolean;
  isSad?: boolean;
}

const RainbowTile: React.FC<TileProps> = (props) => {
  const { children, isRainbow, isSad, className = "" } = props;

  return <div className={`RainbowTile ${isSad ? "isSad" : ""} ${isRainbow ? "isRainbow" : ""} ${className}`}>{children}</div>;
};

export default RainbowTile;
