import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import './ArticleHeroSection.scss';

interface Props {
  article: any
}

const ArticleHeroSection: React.FC<Props> = (props) => {
  const { article } = props;
  return (
    <section className="ArticleHeroSection py-4 py-xl-5">
      <Container>
        <Row align="center">
          <Col size={[8, 10, 12]} offset={[2, 1, 0]} className="text-center">
            <p className="category py-2 py-xl-4">
              {article?.categories?.data?.map((x: any, index: number) => {
                return <span key={index}>&nbsp;#{x?.attributes?.Name}&nbsp;</span>
              })}
            </p>
            <h1 className="h1 pt-2 pb-3 py-xl-3">
              {article?.Title}
            </h1>
            {article?.author ?
              <div className="author py-2 py-xl-3">
                <div className="avatar">
                  <img src={article?.author?.data?.attributes?.Avatar?.data?.attributes?.url} alt={article?.author?.name} />
                </div>
                <p className="name">{article?.author?.data?.attributes?.Name}</p>
                <p className="date">{article?.Date}</p>
              </div>
              : null}
          </Col>
          <Col size={12} className="py-3 py-xl-4">
            <img className="w-100" src={article?.PreviewImage?.data?.attributes?.url} alt={article?.Title} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ArticleHeroSection;