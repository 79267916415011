import React from "react";
import Col from "../../../../libs/grid/col";
import Container from "../../../../libs/grid/container";
import Row from "../../../../libs/grid/row";
import "./IrmatiqUsage.scss";
import { useLang } from "../../../../features/lang-switch/useLang";
import RainbowTile from "./components/Tile/RainbowTile";

interface IrmatiqUsersProps { }

const IrmatiqUsers: React.FC<IrmatiqUsersProps> = (props) => {
  const { T } = useLang();

  const usageTile = (x: string | React.ReactNode, index: number) => {
    return (
      <Col size={[6, 6, 6, 12]} className="py-md-2 py-1" key={index}>
        <RainbowTile isRainbow>
          <h3 className="text-center">{x}</h3>
        </RainbowTile>
      </Col>
    );
  };
  return (
    <section className="IrmatiqUsage IrmatiqUsers py-3 pt-4 py-xl-5">
      <Container className="pb-md-4 pb-3">
        <Row className="pt-md-5 pt-3 pb-3">
          <Col size={[8, 8, 10, 12]} offset={[2, 2, 1, 0]} className="section-intro">
            <h2 className="py-xl-3 color-white">{T("Dla kogo jest Irmatiq?", "Where can you use Irmatiq?")}</h2>
            <p className="py-3 color-white">{T("Świetnie odnajdujemy się tam,  gdzie projekt realizowany jest z pomocą wielu inwestorów czy interesariuszy.", "We thrive when a project is funded or supported by multiple investors or stakeholders")}</p>
          </Col>
        </Row>
        <Row>
          <Col size={[10, 10, 12, 12]} offset={[1, 1, 0, 0]}>
            {T(<Row gap={8}>{[
              "Spółki przed i po crowdinvestingu (crowdfundingu udziałowym)",
              "Pożyczkobiorcy- spółki przed i po crowdlendingu",
              "Grupy/Kluby inwestorskie",
              "Fundacje i stowarzyszenia finansowane przez darczyńców",
              "Projekty nieruchomościowe realizowane z udziałem inwestorów",
              "Projekty finansowane  przez społeczności"
            ].map((x, index) => usageTile(x, index))}</Row>, <Row gap={16}>{[
              "Companies before and after equity crowdfunding",
              "Debt- companies after and before crowdlending",
              "Investor Groups",
              "Foundations and NGOs funded by donors",
              "Real Estate ventures funded by multiple investors",
              "Projects financed by communities"
            ].map((x, index) => usageTile(x, index))}</Row>)}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IrmatiqUsers;
