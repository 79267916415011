import React from "react";
import CallBtn from "../../../../components/navbar/CallBtn/CallBtn";
import { useLang } from "../../../../features/lang-switch/useLang";
import Container from "../../../../libs/grid/container";
import CloseToSection from "../CloseToSection/CloseToSection";
import './InfoSection.scss';
import AImg from "./1.svg";
import BImg from "./2.svg";
import ImgInfoRow from "../../../../components/ImgInfoRow/ImgInfoRow";

interface InfoSectionProps {
}

const InfoSection: React.FC<InfoSectionProps> = (props) => {
  const { T } = useLang();
  return (
    <section className="InfoSection py-5 pt-5 mt-3 mt-lg-0 py-xl-5">
      <Container className="py-0 py-xl-5">
        <CloseToSection />
        <ImgInfoRow
          icon={undefined}
          reverse
          img={AImg}
          noShadow
        >
          <h3 className="py-2 py-xl-3">
            {T(
              <>Dla spółek z co najmniej 3 inwestorami</>,
              <>For companies with 3+ investors</>
            )}
          </h3>
          <p className="py-2 py-xl-3">
            {T(
              <>Niezależnie od tego, czy masz paru, czy kilka tysięcy inwestorów, postaw na wygodnie narzędzie do ich kompleksowej i sprawnej obsługi</>,
              <>Whether you have 3 investors or a few thousand, you can rely on one convenient tool to handle them all, comprehensively and efficiently</>
            )}
          </p>
          <br />
          <CallBtn />
        </ImgInfoRow>
        <ImgInfoRow
          icon={undefined}
          img={BImg}
          noShadow
        >
          <h3 className="py-2 py-xl-3">
            {T(
              <>Trudno Ci wyobrazić swój biznes bez inwestorów?</>,
              <>Can't you imagine your business without investors?</>
            )}
          </h3>
          <p className="py-2 py-xl-3">
            {T(
              <>
                Zadbaj o sprawną komunikacje i dobre relacje z nimi
                <br /><br />
                Zwiększ szansę na sukces kolejnej emisji akcji lub długu. Równocześnie, zaoszczędź czas na obsłudze inwestycji
              </>,
              <>
                Maintain good relationships and effective communication with them.
                <br /><br />
                Increase your company's chances of success with its next equity or debt issue. In the meantime, save time on investment service
              </>
            )}
          </p>
          <br />
          <CallBtn />
        </ImgInfoRow>
      </Container>
    </section>
  );
};

export default InfoSection;
