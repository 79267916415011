import React from "react";
import CallBtn from "../../../../components/navbar/CallBtn/CallBtn";
import { useLang } from "../../../../features/lang-switch/useLang";
import Container from "../../../../libs/grid/container";
import './InfoSection.scss';
import ImgInfoRow from "../../../../components/ImgInfoRow/ImgInfoRow";
import CloseToSection from "../CloseToSection/CloseToSection";
import AImg from "./1.svg";
import BImg from "./2.svg";
import CImg from "./3.svg";
import DImg from "./4.svg";


interface InfoSectionProps {
}

const InfoSection: React.FC<InfoSectionProps> = (props) => {
  const { T } = useLang();
  return (
    <section className="InfoSection py-3 pb-5 py-xl-5">
      <Container className="py-0 py-xl-5">
        <CloseToSection />
        <ImgInfoRow
          icon={undefined}
          reverse
          img={AImg}
          noShadow
        >
          <h3 className="py-2 py-xl-3">{T(
            "Intuicyjny IRM/CRM do zarządzania komunikacją i relacjami z inwestorami",
            "Intuitive IRM/CRM to manage communications and relations with your investors"
          )}</h3>
          <p className="py-2 py-xl-3">
            {T(
              <>
                Postaw na wygodę i stwórz własną listę ze wszystkimi inwestorami. Dzięki temu możesz szybko powiadomić ich o nowej serii akcji czy ważnych informacjach
                <br />
                Zamiast pisać wiadomości, wolisz coś przekazać inwestorom bezpośrednio? Z Irmatiq łatwo zorganizujesz webinary. Spełnianie obowiązku komunikacyjnego wobec inwestorów jeszcze nigdy nie było tak proste
              </>,
              <>
                Choose convenience and create your own IR page with all of your investors. This allows you to quickly notify them about your new stock series or any other important news and information.
                <br />
                Instead of writing messages, would you prefer to communication more directly with your investors? With Irmatiq, you can easily organize webinars. Fulfilling your communication obligations to investors has never been so easy
              </>
            )}
          </p>
          <br />
          <CallBtn />
        </ImgInfoRow>

        <ImgInfoRow
          icon={undefined}
          img={BImg}
          noShadow
        >
          <h3 className="py-2 py-xl-3">{T("Wybierz porządek w dokumentach Twojej spółki", "Keep your company's documents in order")}</h3>
          <p className="py-2 py-xl-3">
            {T(
              <>
                Zarządzaj z jednego miejsca obiegiem wszystkich dokumentów dla inwestorów. Przechowuj pliki przypisane do odpowiednich inwestorów z IRM/CRM, a także generuj i podpisuj dokumentacje finalizujące inwestycję
              </>,
              <>
                Manage the flow of all your investor documents from just one place. Keep track of files assigned to them with the IRM/CRM. You can also generate and sign documents finalizing an investment
              </>
            )}
          </p>
          <br />
          <CallBtn />
        </ImgInfoRow>

        <ImgInfoRow
          icon={undefined}
          reverse
          img={CImg}
          noShadow
        >
          <h3 className="py-2 py-xl-3">{T("Konto Inwestora", "Investor account")}</h3>
          <p className="py-2 py-xl-3">
            {T(
              <>
                Twój Inwestor otrzymuje dostęp do Konta Inwestora.  Znajdzie tam najważniejsze dokumenty, informacje i aktualności, dzięki czemu nie będzie musiał kontaktować się ze spółką w każdej sprawie
              </>,
              <>
                Your investors will receive access to an Investor Account. This is where they will find the most important documents, information and news, so that they no longer need to contact you for those things
              </>
            )}
          </p>
          <br />
          <CallBtn />
        </ImgInfoRow>

        <ImgInfoRow
          icon={undefined}
          img={DImg}
          noShadow
        >
          <h3 className="py-2 py-xl-3">{T("Strona Relacji Inwestorskich (soon)", "Investors relations page (soon)")}</h3>
          <p className="py-2 py-xl-3">
            {T(
              <>
                Zakładka relacje inwestorskie sprawia problem niejednej spółce akcyjnej i PSA, dla których jest obowiązkowa
                <br />
                Aby ułatwić Ci przygotowanie takiej strony, oddajemy do Twojej dyspozycji intuicyjny edytor, dzięki czemu przygotujesz taką zakładkę bez udziału programisty
              </>,
              <>
                To make it easier for you, Irmatiq provides you with an intuitive tool to help you build your own Investor Relations tab - no IT skills necessary.
              </>
            )}
          </p>
          <br />
          <CallBtn />
        </ImgInfoRow>

      </Container>
    </section>
  );
};

export default InfoSection;
