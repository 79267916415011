import React from "react";
import "./top-link.scss";
import { NavLink } from "react-router-dom";
import { showSlideOverlay } from "../router-transition/routerTransitionSlice";
import { useAppDispatch } from "../../redux/store";

interface Props {
  to: string;
  children: React.ReactNode | string;
  // ref?: any;
  className?: string;
  title?: string;
}

const TopLink: React.FC<Props> = (props: Props) => {
  var dispatch = useAppDispatch();

  const slideAndScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(showSlideOverlay(props.to));
  };

  return (
      <NavLink to={props.to} title={props.title ? props.title : ""} onClick={(e) => slideAndScroll(e)} className={({ isActive }) => `topLink ${isActive ? "active" : ""} ${props.className ?? ''}`}>
        {props.children}
      </NavLink>
  );
};

export default TopLink;
