// var host = 'http://localhost:1337';
var host = 'https://api.irmatiq.com';

export function fetchWrapper(path: string, fields: Array<string>, lang: string, parsedStringFields?: string) {
  return new Promise<{ data: any }>((resolve) => {

    fetch(host + path + (parsedStringFields ? parsedStringFields : "") + (fields && fields.length > 0 ? fields.join("&") : "") + "&locale=" + lang, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response =>
        response.text()
      )
      .then(text => {
        return text.split("/uploads/").join(host + "/uploads/");
      }
      )
      .then(data => {
        setTimeout(() => {
          resolve(JSON.parse(data).data)
        }, 10);
      })
      .catch(err => {
        console.log(err)
      })
  });
}