import React, { useEffect, useState } from "react";
import { html } from "../../../../api/html";
import edjsParser from "editorjs-parser";
import ContentSection from "../ContentSection/ContentSection";

interface Props {
  blocksData?: any;
}

const ContentBlocks: React.FC<Props> = (props) => {
  const { blocksData } = props;

  const customParsers = {
    checklist: (data: any, config: any) => {
      return "checklist not supported; ";
    },
    warning: (data: any, config: any) => {
      return "warning not supported; ";
    }
  }

  const [blocks, setBlocks] = useState<any>([]);
  const parser = new edjsParser(undefined, customParsers);

  const reparse = () => {
    setBlocks([]);
    if (blocksData) {
      var contentEditor = JSON.parse(blocksData);
      var blocksCopy = contentEditor.blocks.map((block: any, index: number) => {
        return (
          <React.Fragment key={index}>
            {html(parser.parseBlock(block))}
          </React.Fragment>
        )
      })
      setBlocks(blocksCopy);
    }
  }

  useEffect(() => {
    reparse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocksData])

  return (
    <>
      <ContentSection>
        {blocks}
      </ContentSection>
    </>
  );
};

export default ContentBlocks;