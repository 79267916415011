import React from "react";
import CookieConsent from "react-cookie-consent";
import { useLang } from "../../features/lang-switch/useLang";
import { RoutePaths } from "../../route-paths";
import TopLink from "../top-link/top-link";
import "./CookiePopup.scss";

const CookiePopup: React.FC = () => {
  const { T, lang } = useLang();

  return (
    <CookieConsent
      location="bottom"
      buttonText={T("ZAAKCEPTUJ", "ACCEPT")}
      cookieName="irmatiq_cookie"
      style={{
        boxShadow: "0px 0px 2px rgba(0,0,0,0.1)",
        maxWidth: "1120px",
        height: "auto",
        fontWeight: "300",
        background: "white",
        fontSize: "14px",
        border: "1px solid rgba(0,0,0,0.1)",
        color: "black",
        left: "50%",
        transform: "translateX(-50%)",
        margin: "6px 0px",
        lineHeight: "1.25",
        borderRadius: "8px",
      }}
      buttonStyle={{
        color: "white",
        fontSize: "11px",
        fontWeight: "600",
        letterSpacing: "0.05em",
        background: "black",
        borderRadius: "8px",
        padding: "12px 16px 11px 16px"
      }}
      expires={150}
    >
      {T(
        "Ta strona wykorzystuje pliki cookie (niewielkie pliki tekstowe przechowywane przez przeglądarkę internetową na urządzeniu Użytkownika) w szczególności do analizy statystycznej ruchu, dopasowania wyglądu i treści strony do indywidualnych potrzeb użytkownika oraz dla celów marketingowych (wyświetlania użytkownikowi reklam dostosowanych do jego preferencji). Pozostawiając w ustawieniach przeglądarki włączoną obsługę plików cookie, wyrażasz zgodę na ich użycie. Jeśli nie zgadzasz się na wykorzystanie plików cookie, zmień ustawienia swojej przeglądarki. Aby dowiedzieć się więcej o plikach cookie oraz możliwości zmiany ich ustawień odwiedź naszą "
        ,
        "Ta strona wykorzystuje pliki cookie (niewielkie pliki tekstowe przechowywane przez przeglądarkę internetową na urządzeniu Użytkownika) w szczególności do analizy statystycznej ruchu, dopasowania wyglądu i treści strony do indywidualnych potrzeb użytkownika oraz dla celów marketingowych (wyświetlania użytkownikowi reklam dostosowanych do jego preferencji). Pozostawiając w ustawieniach przeglądarki włączoną obsługę plików cookie, wyrażasz zgodę na ich użycie. Jeśli nie zgadzasz się na wykorzystanie plików cookie, zmień ustawienia swojej przeglądarki. Aby dowiedzieć się więcej o plikach cookie oraz możliwości zmiany ich ustawień odwiedź naszą "

      )}
      <TopLink to={RoutePaths.RULES.url[lang]} className="classic-text">
        <u style={{ color: "black" }}>{T("Politykę prywatności", "Privacy policy")}</u>.
      </TopLink>
    </CookieConsent>
  );
};

export default CookiePopup;
